.referral-wrapper {
  .header-back-grp-referral {
    text-align: center;
    padding: 0 15px;
    .btn-back-referral {
      margin-left: -5px;
      margin-top: 0px;
      padding: 0px;
      .img-control-back-referral {
      }
    }
  }

  .box-img-referral {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    width: fit-content;
    margin-top: 50px;
    margin-bottom: 50px;
    img.img-referral-starbucks {
      margin: 0px;
    }
    .reward-value-card {
      font-size: 40px;
      line-height: 47px;
      font-weight: 800;
      color: white;
      position: absolute;
      bottom: 8px;
      right: 16px;
    }
  }
}
