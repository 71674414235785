.recommend-card-detail-container {
  .top-notification-banner {
    margin-right: 0px;
    margin-bottom: 24px;
    padding: 15px;
    height: fit-content;
    .txt-link-more {
      padding-top: 0px !important;
      text-align: left;
    }
    .view-custom-btn {
      margin-top: 14px;
      margin-left: 0px !important;
    }
  }
  .earned-details {
    line-height: 28px !important;
  }
  .container-content-reward {
    padding-left: 0px;
    padding-right: 0px;
    .content-reward {
      padding-left: 0px;
      padding-right: 0px;
    }
    .box-reward {
      padding-top: 0px !important;
      .recommended-card-details {
        padding: 0px !important;
        .card-full-name {
          font-size: 16px !important;
          line-height: 19px;
        }
      }
      .recommended-money-title {
        width: 120px;
        font-size: 24px;
        line-height: 28px;
      }
      .based-on-your-last {
        padding: 12px 0px;
      }
    }
    .line-item-detail {
      padding-top: 35px !important;
      .line-content {
        padding-left: 0px;
      }
    }
    .line-item-detail-no-padding {
      padding-top: 0 !important;
    }
    .line-item-detail-end {
      margin-top: 24px;
      padding-top: 24px !important;
      border-top: 1px solid #cccccc;
    }
    .based-excellent {
      padding-left: 0px;
      font-size: 16px;
      line-height: 19px;
      text-align: left !important;
      padding-top: 48px !important;
    }
  }
  .btn-link-more-account {
    width: 223px !important;
    font-size: 16px !important;
    line-height: 19px !important;
  }
  .box-card-info {
    @media only screen and (max-width: 600px) and (min-width: 575px) {
      padding-top: 35px;
      flex-direction: column;
      .card-name {
        margin-left: 0px !important;
        margin-top: 16px !important;
        .card-full-name {
          margin-bottom: 12px;
        }
      }
    }
  }
}
.modal-recommended-mb-learn-more {
  width: calc(100vw - 50px);
  margin: auto;
  .modal-content {
    min-height: unset !important;
  }
  .modal-recommended-learn-more {
    min-height: unset !important;
    height: 540px !important;
  }
  .modal-body {
    min-height: unset !important;
    height: 100% !important;
    .title-chances {
      font-size: 24px;
      line-height: 28px;
      margin-bottom: 16px;
    }
  }
  .btn-modal-bottom-learn-more {
    position: absolute;
    bottom: 15px;
    left: 50%;
    transform: translateX(-50%);
  }
}
.modal-recommended-mb-maximum {
  width: calc(100vw - 50px);
  padding: 0px !important;
  margin: auto;
  .modal-recommended-maximum {
    min-height: unset !important;
  }
  .modal-body {
    padding: 0;
    min-height: unset !important;
  }
  .modal-content {
    min-height: unset !important;
    height: 380px !important;
    .title-chances {
      font-size: 24px;
      line-height: 28px;
      margin-bottom: 16px;
    }
  }
}

.modal-changeApproval-mobile{
  .modal-content{
    min-height: 420px !important;
  }
}