.card-validation-page {
  color: #000000;
  font-family: Karla;
  font-size: 12px;
  padding-top: 15px;
  margin-bottom: 40px;
  .card-validation-container {
    width: 100%;
    @media (max-width: 360px) {
      padding: 0 10px;
    }
  }
  ul {
    list-style-type: none;
    margin-top: 24px;
    margin-bottom: 0;
    padding: 0;
  }
  .card-validation {
    &__label {
      font-size: 20px;
      font-weight: bold;
      text-align: center;
    }
    &__title {
      font-size: 30px;
      font-weight: bold;
      letter-spacing: -1.25px;
      line-height: 36px;
      margin-bottom: 16px;
    }
    &__message {
      font-size: 16px;
      letter-spacing: -0.67px;
      line-height: 19px;
    }
    &__input {
      width: 100%;
      height: 56px;
      margin-top: 24px;
      margin-bottom: 140px;
      input {
        width: 100%;
        height: 100%;
        font-size: 16px;
        border: 1px solid #979797;
        border-radius: 4px;
        padding: 14px !important;
      }
    }
    &__btn {
      width: 223px;
      height: 56px;
      line-height: 56px;
      border-radius: 28px;
      background-color: #3f52c7;
      font-size: 16px;
      text-align: center;
      color: #fff;
      margin: 0 auto;
      cursor: pointer;
      &.disabled {
        opacity: 0.5;
        pointer-events: none;
      }
    }
    &__btn-text {
      font-size: 16px;
      font-weight: bold;
      color: #3f52c7;
      text-align: center;
      margin-top: 24px;
      cursor: pointer;
    }
    .card-name {
      max-width: 60%;
      line-height: 14px;
      margin-bottom: 8px;
      @media (max-width: 400px) {
        max-width: 40%;
      }
    }
    .card-image {
      width: 77px;
      height: 49px;
      border-radius: 5px;

      display: flex;
      align-items: center;
      justify-content: center;

      &.border {
        border: 1px solid #ccc;
      }
      img {
        width: 75px;
        height: 47px;
        border-radius: 5px;
      }
    }
    .card-btn {
      font-weight: bold;
      color: #3f52c7;
      position: absolute;
      bottom: 16px;
      right: 16px;
      cursor: pointer;
      .icon-check {
        width: 33px;
        height: 33px;
      }
    }
    .card-error {
      padding-bottom: 24px;
      margin-bottom: 24px;
      border-bottom: 4px solid #cccccc;
      li {
        border: 1px solid #d83e2e;
      }
    }

    .card-name-input {
      width: 100%;
      position: relative;
      span {
        display: block;
        opacity: 0;
        color: #cd0000;
        margin-left: 0;

        position: absolute;
        left: 0;
        bottom: -20px;
      }
      input[type='text'] {
        width: 100%;
        height: 49px;
        border: 1px solid #979797;
        border-radius: 4px;
        padding: 14px !important;
        &:focus,
        &:active {
          outline: none;
        }
      }
      &--error {
        input[type='text'] {
          border: 1px solid #cd0000;
        }
        span {
          opacity: 1;
        }
      }
    }
  }
  ul.card-validation__list {
    max-height: 500px;
    overflow-y: auto;
    margin-bottom: 40px;
    li {
      box-sizing: border-box;
      border: 1px solid #e8e8e8;
      border-radius: 12px;
      background-color: #ffffff;
      padding: 16px;
      position: relative;
      &:not(:last-child) {
        margin-bottom: 16px;
      }
    }
  }
  ul.card-validation__select {
    max-height: 500px;
    overflow-y: auto;
    margin-bottom: 40px;
    padding-bottom: 20px;
    li {
      padding: 12px 8px 12px 2px;
      cursor: pointer;
    }
    li:not(:last-child) {
      margin-bottom: 8px;
      border-bottom: 1px solid #cccccc;
    }
    label {
      width: 100%;
      line-height: 1.2em;
      padding-left: 40px;
      cursor: pointer;
      display: flex;
      align-items: center;
      > div {
      }
      span {
        font-size: 14px;
        width: 100%;
        margin-left: 11px;
      }
    }
    input[type='radio'] {
      display: none;
    }
    input {
      & + label {
        position: relative;
        margin-bottom: 0;
        vertical-align: top;
        &:after,
        &:before {
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          display: block;
          width: 20px;
          height: 20px;
          content: '';
          transition: all ease-in-out 0.3s;
        }
        &:before {
          left: -1px;
          pointer-events: none;
          background-color: #fff;
          width: 22px;
          height: 22px;
          border: 1px solid #cccccc;
          border-radius: 50%;
        }
        &:after {
          background-repeat: no-repeat;
          background-position: 50%;
          background-size: 50% 50%;
          border: 1px solid #fff;
          border-radius: 50%;
        }
      }
      &:checked ~ label:before {
        border: 2px solid #cccccc;
        // background-color: #000;
      }
      &:checked ~ label:after {
        border-width: 5px;
        border-color: #fff !important;
        background-color: #000;
      }
    }
  }
}

.card-validation-page {
  &.linked-accounts {
    padding-top: 0;
    margin-bottom: 0;
    .card-validation {
      margin-bottom: 0;
      .card-name {
        font-size: 16px;
        margin-left: 15px;
        line-height: 19px;
        letter-spacing: -0.5px;
        margin-bottom: 0;

        display: flex;
        align-items: center;
      }
      .card-btn {
        font-size: 16px;
      }
    }
    ul.card-validation__list {
      li {
        display: flex;
        justify-items: center;
      }
    }
  }
}
