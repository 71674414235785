.modal-estimate-wrapper {
  max-width: 85vw;
  margin-left: auto;
  margin-right: auto;
  min-height: unset !important;
  .modal-estimate-container {
    padding: 16px 24px !important;
    overflow-y: auto;
  }
  .modal-content {
    min-height: 90vh;
    height: 90vh !important;
    overflow-y: auto;
  }
  .modal-body {
    padding: 0px !important;
    .title-modal {
      font-weight: 800;
      font-size: 30px;
      line-height: 36px;
      letter-spacing: -1.8px;
      max-width: 480px;
    }
    .form-data-container {
      margin-top: 24px;
      .form-control {
        font-size: 24px !important;
        line-height: 28px;
        letter-spacing: -1.44px;
        color: black;
        padding-left: 15px;
      }
      .left-label {
        font-size: 24px !important;
        line-height: 28px;
        letter-spacing: -1.44px;
        color: black;
        position: absolute;
        left: 0;
        top: 0px;
      }
    }
    .container-result {
      margin-top: 24px;
      .title-top-result {
        font-weight: bold;
        width: 129px;
        height: 32px;
        background-color: #3f52c7;
        color: white;
        border-radius: 4px 4px 0 0;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .card-container {
        margin-bottom: 20px;
        border-radius: 12px;
        margin-top: 8px;
        border: 1px solid #e8e8e8;
        max-width: 280px;
        padding: 16px;
        &:first-child {
          border-top-left-radius: 0px;
          margin-top: 0px;
        }
        .card-name {
          font-size: 12px;
          line-height: 14px;
          letter-spacing: -0.37px;
          margin-bottom: 16px;
        }
        .card-logo {
          width: auto;
          height: 47px;
        }
      }
      .value-info {
        padding-left: 10px;
        padding-right: 10px;
      }
      .label-info {
        font-size: 12px;
        line-height: 14px;
      }
    }
    .right-label {
      font-size: 24px;
      top: -2px;
    }
  }
}
.modal-not-found-store-wrapper {
  width: 85%;
  margin-left: auto;
  margin-right: auto;
  .modal-estimate-container {
    padding: 16px 22px !important;
  }
  .description-modal {
    margin-top: 16px;
  }
  .img-banner {
    margin-top: 24px;
    width: 100%;
    height: 341px;
    object-fit: cover;
    object-position: bottom;
  }
  .modal-body {
    height: fit-content !important;
  }
}
