$header-height: 9vh;
$footer-height: 28vh;

body {
  overflow: hidden !important;
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;

  .header {
    position: relative;
    top: 0px;
    width: 100%;
    max-height: $header-height;
    height: $header-height;
    z-index: 3;
    background: white;
    border-bottom: 1px solid #cccccc !important;
    &.is-mobile {
      border-bottom: none !important;
      height: fit-content;
      &.navbar-open {
        position: fixed;
        top: 0px;
        .header-page {
          margin-top: 18px;
        }
      }

      .bm-menu-wrap {
        padding-top: 10px;
        margin-top: -7px !important;
      }
    }
  }

  .footer-item-grp {
    // max-height: $footer-height;
    height: auto;
    padding-top: 10px;
    background-color: #3f52c7;
    // position: fixed;
    // width: 100%;
    padding-left: 15px;
    border-top: 1px solid #cccccc !important;
  }

  .container-page {
    // max-height: 100vh !important;
    height: auto !important;
    overflow: hidden !important;
  }
  .reward-grp-page {
    // height: 85vh !important;
    height: 100vh !important;
    overflow: auto !important;
  }
}

.content-link-account {
  font-size: 16px !important;
  .now-link-your-credit {
    // height: 19px;
    // width: 355px;
    color: #000000;
    font-size: 16px;
    letter-spacing: -0.83px;
    line-height: 23px;
    width: 100%;
    text-align: left;
    word-wrap: break-word;
    padding: 0px 5px;
    padding-bottom: 20% !important;
  }
}

.img-rewards {
  height: 60px;
  width: 53px;
  margin-right: 13px;
}

.reward-content {
  display: flex !important;
  flex-wrap: nowrap;
  padding: 0px 5px;
}

.btn-link-account {
  height: 55px;
  width: 226px;
  margin-top: 15px;
}

.link-account-group {
  // position: absolute;
  // bottom: 2%;
  width: 100%;
  // transform: translateX(-2%);
}

.security-ico {
  width: 63px !important;
  margin-right: 0px;
  padding-right: 0px !important;
}

.container-page {
  overflow-x: scroll !important;
}

// New Reward
.container-page {
  .reward-content-text {
    font-family: 'Sharp Grotesk Medium 25';
    @media (max-width: 360px) {
      font-size: 20px;
    }
  }
  // .img-gift {
  //   height: 90px;
  //   width: auto;
  // }
  .logo-header {
    margin-left: 25px;
    .img-logo {
      margin-top: -5px;
    }
  }

  .nav-btn {
    margin-right: 25px !important;
  }

  .wb-select-grp {
    display: none;
    opacity: 0;
  }

  .rwd-grp {
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 23px;
    overflow: hidden;
    @media (max-width: 400px) {
      padding: 0;
    }
  }

  .smr-grp {
    padding: 0px !important;
    margin: 0px;

    .reward-container {
      padding-left: 0px !important;
    }
  }

  .reward-container {
    .text-center {
      .btn-control-slide {
        display: none;
      }
      .btn-control-slide-next {
        display: none;
      }
    }
    .reward-content-summary:focus {
      outline: none;
    }
    .reward-content-summary {
      cursor: pointer;
      height: 200px;
      border: 1px solid #e8e8e8;
      border-radius: 12px;
      box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.1);

      .money-summary {
        font-family: 'Sharp Grotesk Medium 25';
        font-size: 22px;
        letter-spacing: 0;
        line-height: 28px;
        text-align: left;
        margin-top: 0px;
        text-align: center;
      }
    }

    .reward-rectangle {
      background-color: #3f52c7;
      color: #ffffff;
    }

    .earned-summary {
      margin-top: auto;
      margin-left: 15px;
      margin-bottom: 20px;
      .reward-content-summary {
        height: 342px;
        // position: absolute;
        // bottom: 40%;
      }
    }

    .missed-summary-rectangle {
      margin-top: auto;
      margin-bottom: 20px;
      margin-left: 0;
      .reward-content-summary {
        height: 163px;
      }
    }

    .title-card-reward {
      position: absolute;
      bottom: 20px;
      left: 20px;
    }

    .reward-details-content-summary {
      height: 121px;
      border: 1px solid #e8e8e8;
      cursor: pointer;
      border-radius: 12px;
      background-color: #ffffff;
      padding: 20px;
      box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.1);
    }
    .card-name {
      font-size: 16px;
      letter-spacing: -0.5px;
      line-height: 19px;
    }

    .card-details {
      position: absolute;
      bottom: 30px;
      left: 0;
      right: 0;
    }

    .spent-item {
      font-size: 16px;
      letter-spacing: -0.38px;
      line-height: 19px;
      margin-top: 16px;
      min-width: 48px;
      // border-right: 1px solid #cccccc !important;
      // padding-right: -20px;
    }
    .spent-item-missed {
      font-size: 16px;
      letter-spacing: -0.38px;
      line-height: 19px;
      margin-top: 32px;
      min-width: 90px;
      // border-right: 1px solid #cccccc !important;
      // padding-right: -20px;
    }

    .border-missed-item {
      margin-top: 32px !important;
    }

    .border-right {
      border-right: 1px solid #cccccc !important;
      // height: 30px;
      width: 1px;
      margin-top: 20px;
      margin-left: 12px;
      margin-right: 12px;
    }

    .rewards-item {
      font-size: 16px;
      // font-weight: 800;
      min-width: 48px;
      letter-spacing: -0.38px;
      line-height: 19px;
      margin-top: 16px;
    }

    .rewards-item-missed {
      font-size: 16px;
      // font-weight: 800;
      // min-width: 90px;
      letter-spacing: -0.38px;
      line-height: 19px;
      margin-top: 32px;
    }

    .rewards-price {
      letter-spacing: -0.38px;
      line-height: 19px;
      font-weight: 900;
      font-size: 17px;
    }

    .date-time-select-reward {
      border: none !important;
      text-align: center;
      padding-top: 5px !important;
      color: #000000;
    }
    .date-time-select-reward:focus {
      outline: none !important;
    }

    .rw-card-details {
      padding-top: 20px;

      .card-item-ct {
        font-size: 16px;
        color: #000000;
        letter-spacing: -0.67px;
        line-height: 19px;
        padding-top: 10px;
      }
    }

    .next-ico-reward-grp {
      position: absolute;
      bottom: 10px;
      right: 10px;
      @media (max-width: 360px) {
        img {
          width: 24px !important;
          height: 24px !important;
        }
      }
    }

    .reset-position-flex {
      flex: 0 0 36px;
    }
    .reset-padding-btn {
      padding: 0 !important;
    }

    .earned-details {
      font-size: 24px;
      font-weight: 800;
      letter-spacing: -0.94px;
      line-height: 36px;
      margin-top: 10px;
      padding-bottom: 24px;
      color: #000000;
      padding-left: 0px !important;

      .total-cost {
        color: #3f52c7;
        font-weight: 800;
        letter-spacing: -0.94px;
        font-size: 30px;
      }
    }

    .btn-link-more-account {
      margin-top: 30px !important;
      height: 56px;
      width: 252px;
      margin-top: 15px;
      font-size: 20px;
      letter-spacing: -1px;
      line-height: 23px;
    }

    .reward-item {
      height: 148px;
      // border: 1px solid #e8e8e8;
      border-radius: 4px;
      background-color: #ffffff;
      // padding: 20px;
      // box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.1);
    }

    .reward-item-missed {
      height: 148px;
      // border: 1px solid #e8e8e8;
      border-radius: 4px;
      background-color: #ffffff;
      // padding: 20px;
      // box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.1);
    }
    .total-money-item {
      font-size: 24px;
      font-weight: 800;
      letter-spacing: -1.44px;
      line-height: 28px;
      color: #000000;
    }

    .month-select {
      min-height: 40px;
      padding: 15px;
    }

    .month-select-group {
      margin: auto;
      width: fit-content;
      display: flex;

      .month-input {
        width: 80px;
        margin: 0px !important;
        .date-time-select-reward {
          width: 80px;
          padding: 0px;
        }
      }

      .img-control {
        width: 12px;
        height: 12px;
      }

      .img-control-next {
        width: 15px;
        height: 15px;
      }
    }

    .top-transaction {
      font-size: 24px;
      font-weight: 800;
      letter-spacing: -0.75px;
      line-height: 28px;
    }

    .header-transaction {
      padding-top: 30px;
      padding-bottom: 30px;
      font-size: 12px;
      letter-spacing: -0.38px;
      line-height: 14px;
      .cus-margin {
        margin-right: 10px;
      }
    }

    .title-transaction {
      // font-size: 12px;
      letter-spacing: -0.5px;
      line-height: 14px;
    }
    .sub-title-transaction {
      font-size: 12px;
      letter-spacing: -0.5px;
      line-height: 14px;
      margin-top: 8px;
    }

    .sub-title-transaction-missed {
      font-size: 12px;
      letter-spacing: -0.5px;
      line-height: 14px;
    }

    .out-earning-btn {
      padding: 0px;
      font-size: 12px;
      letter-spacing: -0.5px;
      line-height: 14px;
    }

    .title-welcome {
      font-size: 16px;
      letter-spacing: -0.5px;
      line-height: 19px;
      padding-bottom: 30px;
      padding-top: 10px;
    }

    .total-bunus {
      font-size: 36px;
      font-weight: 300;
      letter-spacing: -2.16px;
      line-height: 42px;
      text-align: center;
    }

    .bonus-details {
      font-size: 12px;
      letter-spacing: -0.38px;
      line-height: 14px;
      text-align: center;
      padding-top: 20px;
      padding-bottom: 30px;
    }

    .welcom-bonus-group {
      border-bottom: 5px solid #cccccc !important;
      margin-bottom: 30px !important;
    }

    .header-back-grp {
      padding-bottom: 25px;
      margin-left: 25px;
      margin-right: 25px;
      margin-top: 22px;
      button {
        border: none;
        background: none;
        cursor: pointer;
      }

      button:focus {
        outline: 0;
      }

      .title-selected {
        font-size: 16px;
        letter-spacing: -0.96px;
        line-height: 19px;
        font-weight: bold;
        margin-left: -30px;
      }

      .img-control-back {
        height: 30px;
      }
    }
    .title-progress {
      font-size: 24px;
      letter-spacing: -0.75px;
      line-height: 28px;
    }
    .cracking-bonus,
    .spend-bonus {
      font-size: 16px;
      letter-spacing: -0.5px;
      line-height: 19px;
      padding-top: 20px;
    }

    .progress-bar-bonus {
      margin-top: 50px;
    }
    .range-step {
      min-width: 80px;
      font-size: 12px;
      letter-spacing: -0.38px;
      line-height: 14px;
    }
    .last-step {
      margin-top: -30px;
    }

    .first-step {
      margin-top: -30px;
      padding-left: 40px;
    }

    .spent-money-total {
      font-weight: 800;
      letter-spacing: -0.38px;
      line-height: 19px;
    }

    .see-more-reward-title {
      font-size: 24px;
      font-weight: 800;
      letter-spacing: -0.75px;
      line-height: 28px;
    }

    .your-reward-title {
      font-size: 16px;
      letter-spacing: -0.96px;
      line-height: 19px;
    }

    .title-smr-reward {
      font-family: 'Sharp Grotesk Medium 25';
      font-size: 22px;
      letter-spacing: 0;
      line-height: 26px;
      // font-weight: 800;
      padding: 0px 5px !important;
    }

    .rdtPicker {
      left: -80px;
    }

    .user-name {
      font-size: 16px;
      font-weight: 800;
      letter-spacing: -0.96px;
      line-height: 19px;
    }

    .amount-tst {
      margin-top: -5px;
    }

    .category-name {
      white-space: nowrap;
      margin-bottom: 21px;
    }

    .text-category {
      white-space: normal;
      font-size: 16px;
      letter-spacing: -0.5px;
      line-height: 19px;
      font-weight: 800;
    }

    .link-more-account-suggest {
      border-radius: 12px;
      background-color: #3f52c7;
      color: #ffffff;
      font-size: 16px;
      letter-spacing: -0.5px;
      line-height: 19px;
      text-align: center;
      margin-top: 30px;
      // margin-right: 20px;
      position: relative;
      .txt-link-more {
        padding: 15px !important;
        text-align: start;

        .link-more-yes {
          margin-left: 0px !important;
        }
      }
      .btn-close-suggest {
        position: absolute;
        top: 5px;
        right: 0px;
        font-size: 14px;
        color: #ffffff;
        z-index: 1;
      }
    }

    .link-more-account-question-btn {
      margin-top: 12px;
    }
  }

  .bottom-footer {
    position: relative;
    bottom: 0px;
    padding-right: 0px;
    padding-bottom: 20px;
  }

  .copy-right-text {
    padding-top: 40px;
    padding-bottom: 10px;
  }
  .btn-info-footer {
    float: left !important;
  }

  .btn-list {
    display: flex;
    flex-flow: row;
    button:nth-child(1) {
      order: 4;
    }
    button:nth-child(2) {
      order: 3;
    }
    button:nth-child(3) {
      order: 2;
    }
    button:nth-child(4) {
      order: 1;
    }
  }

  .social-grp {
    padding: 0px;
    .connect-us {
      float: left !important;
    }
  }

  .rw-select-mb {
    display: flex;
    flex-flow: row;
    justify-content: space-between;

    .month-year-select-grp-mb {
      margin-left: auto;
      margin-right: 10px;
    }
    .rdtPicker {
      left: -130px !important;
    }
  }

  .rw-list-gr {
    // display: flex !important;
    flex-wrap: nowrap;
  }

  .list-grp {
    padding-left: 0px !important;

    .reward-container {
      padding-left: 0px !important;
    }
  }
  .btn-select {
    margin-top: -8px;
    margin-left: -12px;
  }

  .img-card-grp {
    width: 70px;
    img {
      width: 55px;
      height: 55px;
    }
  }

  .img-card-grp-earned {
    width: 70px;
    align-items: flex-end;
    display: flex;
    img.img-earned-add {
      width: 23px !important;
      height: 23px !important;
      margin-left: 8px;
    }
  }
  .rw-card-grp {
    margin-left: 0px;
    width: auto;
  }

  .rw-card-grp-earned {
    margin-left: 0px;
    width: auto;
    margin: auto;
  }

  .missed-card-img-dt {
    position: absolute;
    right: 40px;
    margin: auto;
  }

  .item-rw-grp {
    padding: 0px;
    margin: 0px;
  }

  .recommend-grp {
    padding: 0px !important;
    padding-left: 10px !important;
  }

  .rw-item-container {
    padding: 0px !important;
    width: 100% !important;
    margin-left: -5px !important;
  }

  .recommend-grp {
    padding: 0px 0px 0px 0.5rem !important;
  }

  .mb-border {
    background-color: #dddddd;
    height: 5px;
    width: 800px;
    margin-left: -20px;
    margin-right: -20px;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .footer-logo {
    .img-logo {
      height: 17px;
      width: 70px;
    }
  }

  .spending-item,
  .personalized-item {
    padding-right: 0px !important;
    padding-bottom: 16px !important;
  }

  .img-item-card {
    width: 60px;
    height: 60px;
  }
  .category-name {
    white-space: nowrap;
    // margin-left: 10px;
    margin-bottom: 16px;
  }

  .text-category {
    white-space: normal;
    max-width: 90px;
  }

  // Missed
  .spent-item-missed {
    font-size: 16px;
    letter-spacing: -0.38px;
    line-height: 19px;
    margin-top: 8px;
    width: 75px;
    border-right: 1px solid #cccccc !important;
    padding-right: 5px !important;

    .spent-price-missed {
      white-space: nowrap;
    }
  }

  .rewards-item-missed {
    font-size: 16px;
    // font-weight: 800;
    // width: 65px;
    letter-spacing: -0.38px;
    line-height: 19px;
    margin-top: 8px;
  }

  .reward-item-missed {
    height: 80px;
    // border: 1px solid #e8e8e8;
    border-radius: 4px;
    background-color: #ffffff;
    // padding: 20px;
    // box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.1);
  }

  .missed-tst-reward {
    padding-top: 20px !important;
  }
}

.arrow-progress {
  margin-left: -5px;
}

.progress-money {
  padding-top: 10px;
  position: absolute;
  top: 15px;
  left: -1;
  margin-left: -30px;
}

.modal-offer {
  .point-grp {
    position: absolute;
    width: auto;
    top: 25%;

    .title-get-offer {
      font-size: 24px;
      font-weight: 800;
      letter-spacing: -1.44px;
      line-height: 28px;
      padding-bottom: 20px;
    }

    .update-point {
      font-size: 16px;
      letter-spacing: -0.5px;
      line-height: 19px;
      padding-bottom: 30px;
    }
  }
}
.earned-reward-wrapper {
  .img-card-grp-earned {
    margin-top: 10px;
    img {
      height: 75px !important;
      width: auto !important;
    }
  }
}

.modal-view-all-benefit {
  .title-get-earning {
    font-size: 16px;
    line-height: 19px;
    display: flex;
  }
  .modal-item {
    overflow-y: auto !important;
  }
  .box-analytic {
    padding: 0px;
    .modal-card-details {
      padding: 12px !important;
      min-height: 92px;
      height: auto;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .title-card-modal {
        flex: 0 0 45px;
        padding: 0px;
        @media screen and (max-width: 600px) {
          flex: auto;
        }
      }
      .text-value {
        flex: 0 0 calc(100% - 45px);
        text-align: left !important;
        padding: 0;
        //padding-top: 14px !important;
        @media screen and (max-width: 600px) {
          flex: auto;
        }
      }
      .box-bottom {
        @media screen and (max-width: 600px) {
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          margin-top: 16px;
        }
      }
      .modal-cash-title {
        text-align: left;
      }
      .modal-cash {
        text-align: left;
      }
    }
  }
  .btn-modal-bottom {
    margin-top: 30px;
  }
}

.modal-missed {
  height: 100%;
  width: 90%;
  .modal-missed-item {
    .modal-body {
      height: 83vh !important;
      .point-grp {
        top: 30px !important;
      }
    }
  }
}

.point-grp {
  top: 10% !important;
  padding: 0px !important;

  .point-container {
    padding: 0px !important;
  }
}

.card-details-select {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
  padding-left: 10px;
  display: flex;
  border-radius: 0 12px 12px 12px;
}

.group-submit-bonus {
  bottom: 10px !important;
}

.title-card-miss {
  margin-left: 0px !important;
  align-items: center;
  display: inline-flex;
}

.border-missed-item {
  display: none !important;
}

.is-web {
  display: none;
}

.continue-prompt {
  margin-top: 20px !important	;
  margin-bottom: 0px !important	;
}

.modal-skip {
  margin: 5px !important;
}
.modal-margin-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}
.body-modal-skip {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  i .question-detail {
    margin-top: 10px !important;
  }
}

.update-account-container {
  padding-top: 0px !important;
}

.full-height {
  height: auto !important;
}

.modal-missed-item {
  height: fit-content !important;
}
.border-center-item {
  margin-left: -10px;
  margin-right: -10px;
  width: auto !important;
}
.personalized-more {
  font-size: 24px !important;
  margin-bottom: 16px !important;
}

.your-best-card {
  width: fit-content !important;
  padding: 6px 18px !important;
}
.carousel {
  .slide {
    // min-width: 327px;
    min-width: calc(100% - 25px);
    padding-right: 8px !important;
    .recommendation-custom-content-summary {
      border-radius: 12px;
    }
  }
  .slide.selected {
    // min-width: 327px;
    min-width: calc(100% - 25px);
    .recommendation-custom-content-summary {
      border-radius: 0px 12px 12px 12px;
    }
  }
}
.recommendation-slide-grp {
  box-shadow: none !important;
}
.recommendation-custom-content-summary {
  .card-content-mobile {
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .card-details {
      position: relative !important;
      bottom: unset !important;
    }
    .card-name-recommanded {
      margin-bottom: 23px;
    }
    .img-card-grp {
      width: 100%;
      font-size: 12px;
      .recommended-your-image-main-card {
        height: 32px;
        width: auto;
      }
    }
    .missed-card-img-dt {
      width: 100%;
      position: relative;
      right: unset;
      padding-left: 0px;
      margin-top: 0px;
      margin-bottom: 0px;
      .you-could-earn {
        margin-right: 0px;
      }
    }
  }
}
.card-name-recommanded {
  font-size: 16px;

  .recommended-your-image-card {
    height: 27px;
    width: auto !important;
  }
}
.recommendation-custom-content-summary-mobile {
  height: 121px !important;
  .next-ico-reward-grp {
    bottom: 18px !important;
  }
}
.img-dot {
  height: 20px;
}
.home-line-break {
  display: none;
}
.recommendation-custom-content-summary-detail {
  height: 212px !important;
  .container-content-card {
    position: relative;
    height: 100%;
    width: 100%;
    .card-details {
      // position: relative !important;
      bottom: unset !important;
      flex-direction: column;
      margin-top: 16px;
      .img-card-grp {
        width: calc(100% + 40px);
        border-bottom: 1px solid #d8d8d8;
        padding-bottom: 16px !important;
        margin-left: -20px;
        margin-right: -20px;
        padding-left: 20px !important;
        padding-right: 20px !important;
        .recommended-your-image-main-card {
          height: 82px;
          width: 125px;
          margin-right: 16px !important;
        }
        .full-name-card {
          font-size: 20px;
        }
      }
    }
    .rw-card-grp.missed-card-img-dt {
      position: relative;
      right: unset !important;
      margin: unset;
      margin-top: 8px;
      .you-could-earn {
        margin-right: 0px;
      }
    }
  }
  .next-ico-reward-grp {
    bottom: 10px !important;
  }
}

.bottom-notification-banner {
  display: block !important;
  margin-top: 8px;
  .txt-question-add {
    font-size: 16px;
    line-height: 19px;
  }
  .view-custom-btn {
    padding-left: 0px;
    color: #3f52c7;
    font-weight: bold;
    line-height: 19px;
    text-decoration: underline;
    text-decoration-color: #3f52c7;
  }
}
.recommend-card-steps-container {
  .top-notification-banner {
    margin-right: 0px;
    // margin-bottom: 24px;
    padding: 15px;
    height: fit-content;
    .txt-link-more {
      padding-top: 0px !important;
      text-align: left;
      display: flex;
      flex-direction: column;
    }
    .view-custom-btn {
      margin-top: 14px;
      margin-left: 0px !important;
      width: fit-content;
    }
  }

  .header-back-grp-referral {
    font-size: 16px;
    font-weight: 800;
    letter-spacing: -1px;
    line-height: 19px;
    text-align: center;
    margin-top: 10px;
    position: relative;
  }

  .btn-back-referral {
    margin-top: -10px !important;
    margin-left: 0px !important;
  }
  .carousel-slider {
    width: 100% !important;
  }
  .carousel .slide.selected {
    min-width: 100%;
    padding-right: 0px !important;
    margin-right: 8px;
  }
}

.title-referral {
  margin-top: 30px !important;
  font-size: 30px;
  font-weight: 800;
  letter-spacing: -1.25px;
  line-height: 36px;
  text-align: left !important;
  margin-left: 0px !important;
}

.img-control-back-referral {
  height: 25px;
}

.container-home-page {
  .reward-container-sm {
    min-width: 312px !important;
    @media (max-width: 360px) {
      min-width: 100% !important;
    }
    .card-content-left {
      max-width: 95%;
      @media (max-width: 360px) {
        font-size: 15px;
      }
    }
  }
}

.social-btn-grp {
  margin-top: 20px;
  padding-left: 0px !important;
  width: 100% !important;
  .twitter-share {
    margin-left: 25px;
  }
  .email-share {
    margin-left: 18px;
  }
}

.social-group-list-btn {
  margin-left: 10%;
  display: block !important;
}

.img-referral-starbucks {
  width: 320px !important;
  height: 220px !important;
  margin: 50px 0px;
}

.img-referral {
  width: 245px;
  height: 253px;
  margin: 50px 0px;
}

.title-invite {
  margin-left: -40px;
}

.starbuck-referral {
  text-align: left !important;
}

.is-mobile-contetn-rfr {
  display: block;
  padding: 0px;
}

.is-web-contetn-rfr {
  display: none;
}
// slider
.carousel-wrapper {
  .carousel-root {
    .carousel-slider {
      width: 267px !important;
      height: 320px !important;
      img {
        height: 282px !important;
      }
      .control-dots {
        bottom: -10px;
        left: 0 !important;
      }
    }
  }
}

.custom-modal-size {
  .modal-body {
    overflow-y: scroll !important;
  }
}

.custom-plaid-footer {
  justify-content: center;
  .security-ico-mobile {
    width: auto !important;
    margin-right: 10px !important;
  }
  .security-text-mobile {
    width: 240px;
  }
}

@media screen and (max-height: 667px) {
  .custom-modal-size {
    .modal-body {
      min-height: 610px;
    }
  }
}

@media screen and (min-height: 668px) {
  .custom-modal-size {
    .modal-body {
      min-height: 610px;
      overflow: hidden !important;
    }
  }
}

// slider

.header-back-grp-menu {
  display: none !important;
}

.bm-menu-wrap {
  margin-top: 10px !important;
}

.hamburger-react {
  margin-right: 20px;
}

.ModalPlaidLinkFail {
  max-width: 592px;
  .modal-body {
    height: unset;
    padding-bottom: 15px;
  }
  .ModalPlaidLinkFail-contents {
    .ModalPlaidLinkFail-title {
      color: #000000;
      font-family: Karla;
      font-size: 24px;
      font-weight: 800;
      letter-spacing: -1.44px;
      line-height: 28px;
      margin-top: 40px;
    }
    .ModalPlaidLinkFail-subtTitle {
      margin-top: 16px;
      p {
        color: #000000;
        font-family: Karla;
        font-size: 16px;
        letter-spacing: -0.5px;
        line-height: 19px;
      }
    }
    .ModalPlaidLinkFail-btn {
      width: 100%;
      display: flex;
      margin-top: 290px;
      justify-content: center;
    }
  }
  .link-more-account-suggest {
    border-radius: 12px;
    background-color: #3f52c7;
    color: #ffffff;
    font-size: 16px;
    letter-spacing: -0.5px;
    line-height: 19px;
    text-align: center;

    position: relative;
    .txt-link-more {
      text-align: start;
      padding: 15px;
      .link-more-yes {
        margin-left: 0px !important;
      }
    }
    .btn-close-suggest {
      position: absolute;
      top: 5px;
      right: 0px;
      font-size: 14px;
      color: #ffffff;
      z-index: 1;
    }
  }
  .link-more-account-question-btn {
    margin-top: 12px;
    border: none;
  }
  &.ModalPlaidLinkFail-v2 {
    .modal-content {
      background-color: unset;
      width: 300px;
      margin-left: auto;
      margin-right: auto;
    }
    .modal-body {
      padding: 0;
    }
  }
}

.reward-summary-wrapper {
  .personalized-content-grp {
    .spending-item,
    .personalized-item {
      .reward-content-summary {
        height: fit-content;
        padding: 16px !important;
        .rw-card-details {
          padding-top: 0px;
        }
        .next-ico-reward-grp {
          bottom: 8px !important;
        }
      }
    }
  }
  .reward-custom-d-flex {
    @media (max-width: 400px) {
      .earned-summary {
        .reward-content-summary {
          margin-right: 5px !important;
        }
      }
      .missed-summary-rectangle {
        .reward-content-summary {
          margin-left: 5px !important;
        }
      }
    }
    .reward-content-summary {
      width: 151px !important;
      @media (max-width: 360px) {
        width: 140px !important;
      }
    }
  }
}

.mobile-small-padding {
  padding-right: 0px;
}

.mobile-btn-padding {
  padding-left: 0rem !important;
}

.margin-right-10 {
  margin-right: 10px;
}

.pre-btn-paid {
  bottom: -322px !important;
  z-index: 1;
}

.next-btn-paid {
  bottom: -327px !important;
  z-index: 1;
}
.no-stats-grp {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .title-no-stats {
    color: #9b9b9b;
  }
}
