.missed-reward-item-wrapper {
  .reward-item-missed {
    height: 100px !important;
    .card-details {
      .rewards-item-missed {
        padding-left: 12px !important;
        .rewards-title,
        .rewards-price {
          text-align: left;
        }
      }
      .spent-item-missed {
        width: fit-content;
        padding-right: 12px !important;
        min-width: unset;
      }
      .spent-title-missed, .rewards-title{
        font-size: 12px;
      }
      .max-width-mobile{
        max-width: 90px;
      }
    }
  }
}
