.custom-recommendation-container {
  .recommendation-custom-content-card {
    height: 135px;
    padding: 16px;
  }
  .container-calc {
    margin-right: 0 !important;
  }
  .label-top-categories {
    font-size: 24px;
    line-height: 28px;
    font-weight: bold;
    padding-top: 31px;
    padding-bottom: 31px;
  }
  .spent-item-recommended {
    .spent-title {
      font-size: 12px;
    }
  }
  .rewards-title {
    font-size: 12px;
  }
}
