.ratings-page-wrapper-parent {
  overflow: hidden;
}
.ratings-page-wrapper {
  background-color: #fafafa;
  height: 90vh;
  overflow: scroll;
  padding-top: 20px;
  overflow-y: auto;
  @media only screen and (max-width: 720px) {
    padding-top: 120px;
    padding-bottom: 40px;
    min-height: 600px;

    overflow: auto;
  }

  &--header {
    position: relative;
    background-color: rgb(255, 255, 255);
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
    display: flex;
    height: 60px;
    justify-content: flex-start;
    text-align: left;
    width: 100%;

    @media only screen and (max-width: 600px) {
      justify-content: center;
    }
  }
  &--header-logo-wrapper {
    display: flex;
    align-items: flex-start;

    position: relative;
    img {
      height: 30px;
    }
  }

  &--body {
    border: solid 1px rgba(0, 0, 0, 0.06);
    padding: 47px;
    box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.02);
    margin-top: 47px;
    margin-left: 64px;
    margin-right: 64px;
    border-radius: 2px;
    margin-bottom: 47px;
    background-color: #ffffff;

    max-width: 650px !important;

    margin: 0 auto;
    text-align: center;
    height: auto;

    @media only screen and (min-width: 1000px) {
      width: 1000px;
    }

    @media only screen and (min-width: 770px) {
      border: 1px solid #ccc;
      border-top: 4px solid #3f52c7;
    }

    @media only screen and (max-width: 600px) {
      margin-left: 14px;
      margin-right: 14px;
      margin-bottom: 14px;
      width: calc(100% - 28px);
      border-top: 4px solid #3f52c7;
    }

    .ratings-page-input-wrapper {
      margin-top: 50px;
      .ratings-page-label {
        color: #616161;
        font-size: 16px;
        margin-bottom: 12px;
      }

      .ratings-page--text-area {
        width: 100%;
        height: 180px;

        @media only screen and (max-width: 600px) {
          width: 100%;
        }
      }
    }
  }
  &--body-title {
    text-align: center;
    margin-top: 30px;
    h1 {
      font-size: 19px;
      font-weight: bold;
      margin-bottom: 20px;
    }
    h5 {
      font-size: 16px;
      margin-bottom: 25px;
    }
    h6 {
      color: #616161;
      font-size: 16px;
      margin-bottom: 41px;
    }
  }

  &--body-contents {
    display: flex;
    justify-content: center;
  }
  .ratings-page--body-logo {
    padding-right: 35px;
  }
  .ratings-page--body-wrapper {
    max-width: 275px;
    text-align: center;
    img {
      border-radius: 15px;
      box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
      margin-bottom: 15px;
    }
    .ratings-page--body-logo-text {
      color: #616161;
      font-size: 14px;

      font-weight: 500;
      line-height: 1.36;
      margin-bottom: 25px;
    }
  }
}

.ratings--btn-submit {
  height: 40px;
  width: 100px;
  background: #3f52c7;
  color: #fff;
  border-radius: 5px;
  min-width: 95px;
  margin-left: 10px;
  text-decoration: none;
  font-family: Karla;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: -0.8px;
  line-height: 19px;
  text-align: center;
  margin: 0;
  margin-top: 30px;
  border: none;

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}

.rating-footer {
  text-align: center;
  font-size: 10px;
  border-top: solid 1px #ccc;

  margin-top: 100px;
  padding-top: 20px;
}
