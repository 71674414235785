$blue-color: #4b64ff;
$gradient-background: linear-gradient(155.27deg, #4b64ff 0%, #3c4db6 100%);
$small-font: 12px;
$normal-font: 13px;
$larg-font: 15px;
$largest-font: 17px;

.blue-color {
  color: $blue-color;
}

.btn-base {
  height: 55px;
  border-radius: 28px;
  background: $gradient-background;
  color: #ffffff;
}

.btn-base:focus {
  height: 55px;
  border-radius: 28px;
  background: $gradient-background !important;
  color: #ffffff !important;
}

.btn-login {
  color: #3f52c7;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: -0.83px;
  line-height: 23px;
  line-height: 20px;
  padding-left: 23px;
  // margin-top: 10px;
  // float: right;
}

.btn:focus {
  outline: 0 !important;
  box-shadow: none;
  color: #ffffff;
}

.btn:hover {
  outline: 0 !important;
  box-shadow: none;
  color: #ffffff;
}

.border-bottom {
  border-bottom: 1px solid #cccccc !important;
}

.no-border {
  border: none !important;
}

.close {
  display: none;
}

.m-atuo {
  margin: auto !important;
}

.form-control {
  border: none;
  padding-bottom: 0px;
  border-bottom: 1px solid #cccccc !important;
  font-size: 20px;
  border-radius: 0px !important;
  padding-left: 0px;
}

.form-control:focus {
  border: none;
  padding-bottom: 0px;
  outline: 0 !important;
  border-radius: 0px !important;
  box-shadow: none;
}

.small-font-size {
  font-size: $small-font;
}

.normal-font-size {
  font-size: $normal-font;
}

.lable-input {
  padding-left: 10px;
}

.modal-footer {
  justify-content: center;
}

.PhoneInputInput {
  border: none;
}

.PhoneInputInput:focus {
  border: none;
  outline: 0 !important;
  box-shadow: none;
}

.btn-text {
  background: none;
  text-decoration: underline;
  color: #3f52c7 !important;
}

.btn-text:focus {
  background: none;
  text-decoration: underline;
  color: #3f52c7 !important;
}

.btn-text:hover {
  background: none;
  text-decoration: underline;
  color: #3f52c7 !important;
}

.valid-text {
  color: #81000e;
}

.form-group {
  position: relative;
  .validation-img {
    position: absolute;
    right: 0;
    bottom: 34px;
  }
  .validation-ssn {
    position: absolute;
    right: 0;
    bottom: 70%;
  }
  .validation-date-img {
    position: absolute;
    right: 0;
    bottom: 70%;
  }

  label {
    margin: 0px;
  }
}
.otp-group {
  margin-right: 5px;
  padding-bottom: 0px;
  padding: 0px;
  input {
    width: 100% !important;
    border: none;
    font-size: 26px !important;
    letter-spacing: -0.41px;
    line-height: 31px;
    padding: 0px;
    font-weight: 800;
    // color: red;
  }
  input:focus {
    width: 100% !important;
    border: none;
    outline: 0 !important;
    box-shadow: none;
  }
}

.back-ico {
  font-size: 35px;
  // margin-top: -15px;
  margin-left: -5px;
}

.btn-back {
  background: none;
  border: none;
  padding: 20px 0px;
}

.react-datepicker-wrapper {
  width: 100%;
}

.btn-continue {
  height: 55px;
  width: 226px;
}

.number-input {
  width: 100% !important;
  border: none;
  border-bottom: 1px solid #cccccc !important;
  // margin-left: 10px;
  font-size: 20px;
  padding: 0px;
}

.number-input:focus {
  width: 100% !important;
  border: none;
  outline: 0 !important;
  box-shadow: none;
  // margin-left: 10px;
  // font-size: $normal-font;
}

.logo-navbar {
  padding-left: 20px !important;
}

.login-nav {
  z-index: 2;
}

.btn-text:disabled {
  background: none;
  text-decoration: underline;
  color: rgb(125, 125, 235);
}

.content-continue {
  padding: 0px 0px 30% 0px;
  font-size: 20px;
  letter-spacing: -0.83px;
  line-height: 23px;
}

.blur-container {
  width: 110%;
  height: 100vh;
  background-size: cover;
  position: absolute;
  // opacity: 0.8;
  // background: rgb(45, 45, 45);
  left: -10%;
  background: rgba(61, 61, 61, 0.85);
  z-index: 3;
  // box-shadow: inset 0 0 0 50vw rgba(146, 146, 146, 0.2);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
}

.modal-item {
  // max-height: 98vh;
  padding-left: 10px;
  padding-right: 10px;
  // width: 100%;
  overflow: hidden !important;
  overflow-y: hidden !important;
  padding-top: 0px;
  position: relative;
}

// .modal {
//   overflow-y: hidden !important;
// }

.pt-100 {
  padding-top: 23%;
}

.pb-100 {
  padding-bottom: 70px;
}

.mt-100 {
  margin-top: 100px !important;
}

.btn-close {
  border: none;
  background: none;
  position: absolute;
  right: 0px;
  // margin-top: 5px;
  z-index: 5000;
  padding: 0px;
  margin-right: 19px;
  right: 10px;
  top: 10px;

  font-size: 28px;
  width: 30px;
  height: 30px;
  line-height: 30px;
}

.btn-close:focus {
  border: none;
  background: none;
  font-size: 20px;
  // margin-top: 5px;
  z-index: 5000;
  margin-right: 15px;
  outline: 0 !important;
  box-shadow: none;
}

.text-right {
  text-align: right;
}

.welcome-title {
  padding-top: 5px !important;
  font-weight: bold;
  letter-spacing: -0.83px;
  line-height: 23px;
  font-size: 18px !important;
}

.text-middle {
  display: flex;
  justify-content: center;
  align-items: center;
}

.pl-0 {
  padding-left: 0px !important;
}

.loader-btn {
  padding-left: 20px;
}

.ssn-label {
  position: absolute;
  top: 2px;
  font-size: 20px;
  border-bottom: 1px solid #cccccc !important;
  color: rgb(100, 100, 100);
}

.number-input-ssn {
  width: 100%;
  border: none;
  border-bottom: 1px solid #cccccc !important;
  font-size: 20px;
}

.number-input-ssn:focus {
  border: none;
  outline: 0 !important;
  box-shadow: none;
}

.logo {
  height: 23px;
  width: 93px;
}

h5 {
  font-size: $largest-font;
}

h6 {
  font-size: $larg-font;
}

.pb-5 {
  padding-bottom: 18px !important;
}

.pb-4 {
  padding-bottom: 14px !important;
}

.pt-5 {
  padding-top: 18px !important;
}

.pt-4 {
  padding-top: 12px !important;
}

.pt-3 {
  padding-top: 10px !important;
}

.pt-2 {
  padding-top: 8px !important;
}

.mt-5 {
  margin-top: 18px !important;
}

.mb-3 {
  margin-top: 11px !important;
}

.p-4 {
  padding: 13px !important;
}

label {
  letter-spacing: -0.5px;
  line-height: 14px;
}

.slick-dots li {
  margin: 0px;
}

.terms-conditions {
  margin-top: 0px;
  font-size: 10px;
}

.btn-continue-signup {
  margin-top: 10px !important;
  width: 200px;
  height: 50px;
}

.terms-service {
  padding-top: 1%;
  padding-bottom: 5%;
  padding-left: 5px;
  margin-top: 0px !important;
  padding-right: 5px;
  font-size: 10px !important;
  letter-spacing: -0.5px;
  line-height: 12px;
  z-index: 1000;
  margin-bottom: 0px !important;
}

.navbar {
  position: initial;
  padding: 0px;

  .logo-navbar {
    padding-left: 0px !important;
  }
}
.nav-group {
  border-bottom: none !important;
}

.sub-title-ssn-update {
  font-size: 24px !important;
  font-weight: 800;
  letter-spacing: -1px;
  line-height: 28px;
  color: #000000;
  margin-top: 20px;
  margin-bottom: 24px;
  font-family: 'Karla';
}

.verification-title {
  margin-top: 22px;
  font-size: 20px !important;
  letter-spacing: -0.83px;
  line-height: 23px;
}
.modal-missed-reward-wrapper {
  top: 0;
  margin-top: 40px;
  .btn-close {
    right: 13px;
    top: 14px;
    margin: 0px;
  }
  .modal-earning {
    height: fit-content;
    padding: 0 20px !important;
  }
  .modal-content {
    width: 90vw;
    margin-left: auto;
    margin-right: auto;
  }
  .modal-item {
    padding: 0;
    .content-note {
      padding: 0px;
      padding-bottom: 34px;
    }
  }
}
