.your-card-container {
  .top-notification-banner {
    margin-right: 0px;
    // margin-bottom: 24px;
    padding: 15px;
    height: fit-content;
    .txt-link-more {
      padding-top: 0px !important;
      text-align: left;
      display: flex;
      flex-direction: column;
    }
    .view-custom-btn {
      margin-top: 14px;
      margin-left: 0px !important;
      width: fit-content;
    }
  }
  .carousel {
    .slide {
      img.img-card-slide {
        opacity: 0.5;
      }
    }
    .slide.selected {
      img.img-card-slide {
        opacity: 1 !important;
      }
    }
  }
  .one-card-container-wrapper {
    width: 100% !important;
    margin-left: 0px !important;
    .reward-container {
      padding-left: 15px !important;
    }
    .carousel-slider {
      width: 100% !important;
      .slider {
        padding-right: 0px;
        .slide {
          padding-left: 1px !important;
          padding-right: 1px !important;
          margin-left: 0 !important;
        }
      }
    }
  }
}
