@import './modal.scss';

.logo-group {
  font-size: 1rem !important;
  cursor: pointer;
}
.sub-login-title {
  padding: 20px 0px;
  font-size: 24px !important;
  color: #000000;
  font-weight: 800;
  letter-spacing: -1px;
  line-height: 28px;
  margin-bottom: 0px;
}

.exist-account {
  padding: 0px;
  font-size: 15px !important;
  letter-spacing: -0.67px;
  line-height: 19px;
  margin: 0px !important;
}

.btn-accept {
  height: 55px;
  width: 226px;
  // font-size: 20px;
  // letter-spacing: -1px;
  // line-height: 23px;
  // text-align: center;
}

.btn-signup {
  width: 226px;
  font-size: 20px;
  height: 50px;
}

.btn-signup:focus {
  width: 226px !important;
  font-size: 20px;
  height: 55px !important;
}
.btn-signup:hover {
  width: 226px !important;
  font-size: 20px;
  height: 55px !important;
}
.earned-reward-item-custom {
  .card-details {
    position: relative !important;
    bottom: 0px !important;
    padding: 0 !important;

    .img-earned-reward-item {
      position: relative;
      bottom: 0;
    }
  }
  .reward-details-content-summary {
    height: fit-content !important;
  }
}
