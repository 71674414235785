.earned-reward-item-wrapper {
  .reward-details-content-summary {
    padding: 16px !important;
    .rw-card-grp-earned-spent-reward {
      margin-left: auto !important;
    }
    .spent-item,
    .rewards-item {
      width: fit-content;
      min-width: auto;
    }
    .spent-title,
    .spent-price {
      width: fit-content;
      margin-right: auto;
      margin-left: 0;
    }
    .rewards-title,
    .rewards-price {
      width: fit-content;
    }

    .img-card-grp-earned {
      align-items: flex-end;
      display: flex;
      img.img-earned-add {
        width: 23px !important;
        height: 23px !important;
        margin-left: 8px;
      }
      img {
        height: 47px !important;
        width: auto !important;
      }
    }
    .rw-card-grp-earned {
      margin-right: 20px !important;
      margin-left: auto !important	;
      padding: 0px !important;
    }
  }
}

.item-group-mobile {
  margin-left: -8rem !important;
}

.reward-item {
  .card-details {
    .custom-divider-2 {
      margin-right: 12px !important;
      margin-left: 12px !important;
      padding-right: 0;
      padding-left: 0;
    }
  }
}

.custom-spent-item,
.custom-rewards-item {
  min-width: auto !important;
  width: fit-content !important;
  flex-shrink: 0;
}
