.card-no-recommendation {
  padding-top: 24px !important;
  padding-bottom: 36px !important;
  .recommendation-custom-content-summary {
    border-radius: 12px;
    padding: 40px 59px;
    height: unset;
    @media screen and (max-width: 600px) {
      padding-top: 47px;
      padding-bottom: 33px;
      padding-left: 0;
      padding-right: 0;
    }
  }
  .no-recommendation-icon-box {
    height: 55px;
    width: 55px;
    margin-left: auto;
    margin-right: auto;
    background-color: #3f52c7;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 16px;
    img {
      filter: brightness(0) invert(1);
    }
  }
  .no-recommendation-label {
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    color: black;
    @media screen and (max-width: 600px) {
      line-height: 19px;
      max-width: 220px;
      margin-left: auto;
      margin-right: auto;
    }
    line-height: 19px;
  }
  .no-recommendation-des {
    text-align: center;
    font-size: 12px;
    line-height: 14px;
    margin-bottom: 0;
    @media screen and (max-width: 600px) {
      line-height: 19px;
      max-width: 200px;
      margin-left: auto;
      margin-right: auto;
    }
  }
}
