.your-card-container {
  .content-analytic-card {
    transform: translateY(-80px);
    z-index: 2;
  }
  .best-card {
    width: 130px;
  }
  .card-category-recommended-details {
    .recommended-your-image-card {
      width: 40px !important;
      height: auto !important;
      object-fit: contain;
    }
    .recommanded-money {
      font-size: 24px;
    }
  }
  .card-category-recommended {
    height: unset !important;
    .recommended-your-image-card {
      width: 40px !important;
      height: auto !important;
      object-fit: contain;
    }
    .recommanded-money {
      font-size: 24px;
    }
  }
  .additional-benefits-details {
    margin-top: 16px !important;
  }
  .card-select-name {
    margin-bottom: 7px;
  }
  .carousel {
    .slide {
      img.img-card-slide {
        opacity: 0.5;
      }
    }
    .slide.selected {
      img.img-card-slide {
        opacity: 1 !important;
      }
    }
  }
}
