.uninstall-extension-wrapper {
  background-color: #fafafa;
  height: 100vh;
  overflow: scroll;
  &--header {
    position: relative;
    background-color: rgb(255, 255, 255);
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
    box-shadow: rgba(0, 0, 0, 0.03) 0px 2px 9px 0px;
    display: flex;
    height: 80px;
    justify-content: center;
    text-align: center;
    width: 100%;
  }
  &--header-logo-wrapper {
    display: flex;
    align-items: center;

    position: relative;
  }
  &--header-button {
    position: absolute;
    right: 64px;
    top: 21px;
    button {
      border-color: #3f52c7;
      background-color: #3f52c7;

      cursor: pointer;
      height: 32px;
      outline: none;
      padding: 0px 12px;
      background: none;
      box-shadow: none;
      transition: all 0.2s ease 0s;
      text-shadow: none;
      user-select: none;
      border-style: solid;
      border-width: 1px;
      border-radius: 3px;
      font-size: 14px;
      background: #3f52c7;
      color: #fff;

      img {
        width: 12px;
        margin-right: 10px;
      }
    }
  }

  &--body {
    border: solid 1px rgba(0, 0, 0, 0.06);
    padding: 47px;
    box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.02);
    margin-top: 47px;
    margin-left: 64px;
    margin-right: 64px;
    border-radius: 2px;
    margin-bottom: 47px;
    background-color: #ffffff;
  }
  &--body-title {
    h1 {
      font-size: 28px;
      text-align: center;
      font-weight: bold;
      margin-bottom: 4px;
    }
    h5 {
      color: #616161;
      font-size: 18px;
      text-align: center;
      margin-bottom: 41px;
    }
  }

  &--body-contents {
    display: flex;
    justify-content: center;
  }
  .uninstall-extension--body-logo {
    padding-right: 35px;
  }
  .uninstall-extension--body-wrapper {
    max-width: 275px;
    text-align: center;
    img {
      border-radius: 15px;
      box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
      margin-bottom: 15px;
    }
    .uninstall-extension--body-logo-text {
      color: #616161;
      font-size: 14px;

      font-weight: 500;
      line-height: 1.36;
      margin-bottom: 25px;
    }
  }
  &--body-listItem {
    padding-left: 35px;

    display: flex;
    flex-direction: column;

    .uninstall-extension-item {
      margin-bottom: 22px;
    }
    .uninstall-extension--text-area {
      height: 76px;
      margin-bottom: 25px;
    }
    .uninstall-extension--btn-submit {
      height: 40px;
      width: 100%;
      background: #3f52c7;
      color: #fff;
      border-radius: 5px;
      min-width: 95px;
      margin-left: 10px;
      text-decoration: none;
      font-family: Karla;
      font-size: 16px;
      font-weight: 700;
      letter-spacing: -0.8px;
      line-height: 19px;
      text-align: center;
      margin: 0;
      border: none;

      &:disabled {
        opacity: 0.5;
        pointer-events: none;
      }
    }
    .checkbox-wrapper {
      margin-bottom: 25px;
      &:last-child {
        margin-bottom: 0;
      }
      label {
        margin-bottom: 0;
      }
      input[type='checkbox'] {
        -ms-transform: scale(1.5); /* IE */
        -moz-transform: scale(1.5); /* FF */
        -webkit-transform: scale(1.5); /* Safari and Chrome */
        -o-transform: scale(1.5); /* Opera */
        padding: 10px;
        margin-right: 10px;
      }
    }
  }
}
