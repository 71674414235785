.popver-custom {
  .popover {
    max-width: 327px;
  }
  .popover-body {
    padding: 16px;
    padding-right: 30px;
  }
  .x-close-popver {
    position: absolute;
    top: 12px;
    right: 12px;
  }
}
.bnt-img {
  padding: 0px;
  &:focus-visible {
    outline: none;
  }
  &:focus {
    outline: none;
  }
}
.popver-custom {
  .arrow {
    border: none;
  }
}
.popver-prevent {
  .popover {
    // border: none;
    border-radius: 20px;
  }
}

.marker-custom-wrapper {
  height: 36px;
  width: 36px;
  position: relative;

  svg {
    height: 36px;
    width: 36px;
  }
  .img-icon {
    width: 14px;
    position: absolute;
    top: 4px;
    left: 50%;
    transform: translateX(-50%);
  }
}
.btn:disabled {
  opacity: 0.5;
}
.modal-warning-wrapper {
  .modal-item {
    height: fit-content;
    padding-bottom: 0px;
    .text-warning-exist {
      text-align: center;
    }
    .btn-group {
      display: flex;
      margin-top: 30px;
      width: 100%;
      justify-content: space-around;
      button.btn {
        width: 30%;
        border-radius: 28px;
      }
    }
  }
  @media screen and (max-width: 600px) {
    .modal-content {
      width: 90%;
      margin-left: auto;
      margin-right: auto;
    }
  }
}
.modal-earning-wrapper {
  .modal-earning {
    height: auto;
  }
  .modal-content {
    @media screen and (max-width: 380px) {
      margin-top: 100px;
    }
  }
}
.category-item {
  &:hover {
    text-decoration: none;
  }
}

.progress-bar-wrapper {
  width: 100vw;
  padding: 20px 0px;
  padding-top: 0px;
  position: fixed;
  bottom: 0;
  background-color: white;
  .progress-bar-text {
    margin-top: 20px;
    text-align: center;
  }
  .bar-wrapper {
    height: 12px;
    border-radius: 0px;
    @media screen and (max-width: 600px) {
      height: 8px;
    }
  }
}
