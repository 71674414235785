.earned-reward-wrapper {
  .rw-card-grp-earned-spent-reward {
    margin-right: 25px !important;
    .spent-item,
    .rewards-item {
      width: fit-content;
    }
    .border-right {
      margin-left: 12px !important;
      margin-right: 12px !important;
    }
  }
  .reward-container {
    .spent-title,
    .rewards-title {
      font-size: 12px;
    }
  }
  .img-card-grp-earned {
    align-items: flex-end;
    display: flex;
    img.img-earned-add {
      width: 23px !important;
      height: 23px !important;
      margin-left: 8px;
    }
    img {
      height: 47px !important;
      width: auto;
    }
  }
  .reward-details-content-summary {
    height: 121px !important;
    .rw-card-grp-earned {
      margin-left: auto !important;
    }
  }
}
