.estimate-rewards-container {
  min-height: unset;
  .search-card-container {
    max-height: 400px;
    overflow-y: auto;
  }
  .card-detail-container {
    position: relative;
    min-height: 102px;
    height: auto !important;
    .close-icon-card {
      position: absolute;
      top: 12px;
      right: 12px;
      cursor: pointer;
    }
  }

  .store--box {
    margin-left: auto !important;
    margin-right: auto !important;
    width: auto !important;
    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }
  .search-input-custom {
    padding-bottom: 17px;
    .search-input {
      &__icon {
        height: 24px;
        width: auto;
      }
    }
  }
  .warning-label-search {
    color: #707070;
    text-align: center;
  }
  .btn-continue {
    width: 223px;
    height: 56px;
  }
}
.estimate__near-location-wrapper {
  .title-container {
    margin-top: 10px;
    font-size: 30px;
    line-height: 36px;
    font-weight: 800;
    text-align: center;
  }
  .near-location-container {
    display: flex;
    flex-direction: column;
    .near-location-content {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
    }
  }
  .container-map {
    margin-top: 24px;
    padding: 0px;
    height: 600px;
    position: relative;
    .container-categories-map {
      position: absolute;
      z-index: 2;
      display: flex;
      left: 0;
      right: 0;
      top: 10px;
      justify-content: center;
      .active-box-category {
        .box-category-container {
          border: 1px solid #0000ffa3;
        }
      }
      .box-category-wrapper {
        padding-left: 15px;
        padding-right: 15px;
        .box-category-container {
          background-color: #fff;
          border-radius: 12px;
          padding: 7px 12px;
          box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.1);
          .category-img {
            height: 24px;
          }
          .text-category {
            margin-left: 12px;
            font-weight: normal;
          }
        }
      }
    }
  }
  .container-result-box {
    position: absolute;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    align-items: center;
    left: 0;
    right: 0;
    bottom: 0;
    .content-card-empty {
      margin-bottom: 120px;
    }
    .top-card {
      margin-top: 16px;

      .your-top-card {
        height: 32px;
        width: 129px;
        border-radius: 4px 4px 0 0;
        background-color: #3f52c7;
        color: #ffffff;
        font-family: Karla;
        font-size: 16px;
        font-weight: bold;
        letter-spacing: -0.5px;
        line-height: 19px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .card-detail-container {
        position: relative;
        width: 671px;
        box-sizing: border-box;
        border: 1px solid #cccccc;
        border-radius: 0 12px 12px 12px;
        background-color: #ffffff;
        padding: 12px;
        .close-icon-card {
          position: absolute;
          top: 12px;
          right: 12px;
          cursor: pointer;
        }
        .card-detail-header {
          display: flex;
          align-items: center;

          .name-card {
            margin-left: 14px;
          }
        }

        .info-card {
          color: #000000;
          font-family: Karla;
          font-size: 16px;
          letter-spacing: -0.5px;
          line-height: 19px;
          margin-top: 10px;

          .bold {
            font-weight: 800;
          }

          .underline {
            text-decoration: underline;
          }
        }
      }
    }
    .btn-continue {
      margin: 40px 0px;
    }
  }
}
