@import './modal.scss';

.logo-group {
  font-size: 1rem !important;
  cursor: pointer;
}
.sub-login-title {
  padding: 30px 0px 0px;
  font-size: 30px;
  font-weight: 900;
  letter-spacing: -1px;
  line-height: 36px;
}

.digit-code {
  padding-top: 0px;
  padding-bottom: 50px;
}

.exist-account {
  padding: 5px 0px 15px 0px;
  font-size: 16px !important;
  letter-spacing: -0.67px !important;
  line-height: 19px !important;
}

.btn-accept {
  margin-top: 40px;
  width: 252px;
}

.btn-signup {
  width: 164px;
}

.otp-form {
  width: 70%;
  margin: auto !important;
}
.otp-resend {
  width: 70% !important;
  margin: 5px auto !important;
}

.terms-service {
  margin-top: 180px !important;
  font-size: 9px !important;
  letter-spacing: -0.38px;
  line-height: 10px;
}

.btn-accept {
  margin-top: 10px;
}
