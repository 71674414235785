.container-landding {
  width: 100% !important;
  overflow: hidden !important;
  height: 100vh;
}
.landing-bg {
  // margin-top: -2px;
  height: 100%;
  height: 100vh;
  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
}

.landing-content {
  color: red !important;
  position: relative;
  align-items: center;
  .content {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 20%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
  .slider-title {
    // height: 94px;
    // width: 555px;
    color: #000000;
    font-size: 40px;
    letter-spacing: -1.67px;
    line-height: 47px;
    padding: 0px 8%;
  }

  .slide-show {
    position: absolute;
    top: 30%;
    left: 5%;
  }

  .control-dots {
    position: absolute;
    right: -55%;
    padding: 0px;
    margin: 0px;
    top: -10%;
    height: auto;
    transform: rotate(-90deg);

    .dot {
      background: rgb(0, 0, 0);
      width: 10px;
      height: 10px;
    }
  }

  .slick-dots li button:before {
    font-size: 13px !important;
  }
}

.link-paid {
  .dot {
    margin: 0 5px !important;
  }
}

.main-title {
  font-size: 40px;
  letter-spacing: -1.67px;
  line-height: 47px;
  padding-left: 0px;
  padding-bottom: 40px;
  text-align: left;
}

.sub-title {
  font-size: 24px;
  letter-spacing: -0.38px;
  line-height: 28px;
  padding-left: 0px;
  padding-bottom: 40px;
  text-align: left;
  // line-height: 8px;
}

.reward-ico {
  height: 61px;
  width: 55px;
  border-radius: 4px;
  background: linear-gradient(155.27deg, #4b64ff 0%, #3c4db6 100%);
  img {
    color: white;
    // background: red;
    padding: 7px;
    filter: brightness(0) invert(1);
  }
}

.sub-title-ssn-update {
  padding: 30px 0px 0px;
  margin-bottom: 40px;
  width: 80%;
  font-size: 30px !important;
  font-weight: 800 !important;
  letter-spacing: -1px !important;
  line-height: 28px !important;
  font-family: 'Karla';
}

.img-comeback {
  margin-top: 20px;
}

.login-btn {
  margin-top: 30px !important;
}

.step-title {
  font-size: 16px;
  font-weight: 800;
  letter-spacing: -0.5px;
  line-height: 19px;
}

.otp-info {
  font-size: 16px;
  letter-spacing: -0.67px;
  line-height: 19px;
  font-weight: normal;
}

.valid-text-otp {
  width: 70% !important;
  margin: 5px auto !important;
}

.lock-ico {
  // margin-top: -5px;
  margin-right: 5px;
  height: 18px;
  width: 15px;
}

.digit-code-login {
  font-size: 16px;
  letter-spacing: -0.67px;
  line-height: 19px;
}

.step-info {
  font-size: 16px !important;
  font-weight: 800;
  letter-spacing: -0.5px;
  line-height: 19px;
  font-family: 'Karla';
}

.our-service {
  font-size: 16px !important;
  letter-spacing: -0.67px;
  line-height: 19px;
}

.digit-code {
  font-size: 30px !important;
  font-weight: 800;
  letter-spacing: -1.25px;
  line-height: 36px;
}

.otp-modal-grp {
  padding-top: 40px !important;
}

.lock-container {
  display: flex;
}

.your-rewards-summary-mobile {
  display: none;
}

.custom-home {
  min-height: calc(100vh - 229px);
}

.pt-100 {
  padding-top: 100px !important;
}

.rw-item-container {
  padding-bottom: 30px !important;
}

.modal-options-wed-mobile--title {
  margin-top: 30px;
  p {
    color: #000000;
    font-family: 'Karla';
    font-size: 24px;
    font-weight: 800;
    letter-spacing: -1.44px;
    line-height: 28px;
  }
  // p {
  //   &:last-child {
  //     font-size: 16px;
  //     letter-spacing: -0.5px;
  //     line-height: 19px;
  //   }
  // }
}

.custom-item-body {
  min-height: calc(100vh - 406px);
}

.estimate-rewards-container {
  padding: 44px 47px;
  min-height: calc(100vh - 328px);

  .where-are-you-shoppi {
    height: 36px;
    color: #000000;
    font-family: Karla;
    font-size: 30px;
    font-weight: 800;
    letter-spacing: -1.25px;
    line-height: 36px;
    margin-bottom: 16px;
  }

  .rectangle-container-top,
  .rectangle-container-bottom {
    display: flex;
    justify-content: center;

    .rectangle {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 46px;
      width: 155px;
      border-radius: 4px;
      background-color: #ffffff;
      box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.1);
      margin: 0px 17px;
      cursor: pointer;

      &-active {
        border: 2px solid #3f52c7;
        border-radius: 4px;
        background-color: #ffffff;
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.1);
      }
    }

    .rectangle:nth-child(1) {
      margin-left: 0px;
    }

    .rectangle:nth-child(4) {
      margin-right: 0px;
    }
  }

  .rectangle-container-top {
    margin-top: 32px;
  }

  .rectangle-container-bottom {
    margin-top: 16px;
  }

  .map-places {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 32px;
    transform: translateX(-10px);

    .view-places-near-you {
      color: #3f52c7;
      font-family: Karla;
      font-size: 16px;
      letter-spacing: -0.5px;
      line-height: 19px;
      .bold {
        color: #3f52c7;
        font-weight: bold;
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }

  .btn-next-container {
    display: flex;
    justify-content: center;
    margin-top: 99px;

    button {
      margin-top: 0px;
    }
  }

  .couldnt-find-what-y {
    color: #3f52c7;
    font-family: Karla;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: -0.38px;
    line-height: 14px;
    text-align: center;
    text-decoration: underline;
    margin-top: 99px;
    margin-bottom: 120px;
  }

  .top-card {
    margin-top: 24px;
    .your-top-card {
      height: 32px;
      width: 129px;
      border-radius: 4px 4px 0 0;
      background-color: #3f52c7;
      color: #ffffff;
      font-family: Karla;
      font-size: 16px;
      font-weight: bold;
      letter-spacing: -0.5px;
      line-height: 19px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .card-detail-container {
      box-sizing: border-box;
      height: 102px;
      border: 1px solid #cccccc;
      border-radius: 0 12px 12px 12px;
      background-color: #ffffff;
      padding: 12px;

      .card-detail-header {
        display: flex;
        align-items: center;

        .name-card {
          margin-left: 14px;
        }
      }

      .info-card {
        color: #000000;
        font-family: Karla;
        font-size: 16px;
        letter-spacing: -0.5px;
        line-height: 19px;
        margin-top: 10px;

        .bold {
          font-weight: 800;
        }

        .underline {
          text-decoration: underline;
        }
      }
    }
  }
}

.backgroundLanding {
  .section-landing-1 {
    margin-top: 60px;
    margin-bottom: 130px;
    &--contents {
      width: fit-content;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
    &--col {
      display: flex;
      justify-content: flex-end;
    }
    &--store-contents-mobile {
      display: none;
    }
  }
  .section-landing-2 {
    &--col {
      display: flex;
      justify-content: flex-end;
      padding-right: 120px;
    }
    &--content-mobile {
      display: none;
    }
  }
  h1 {
    height: 280px;
    width: 397px;
    color: #000000;
    font-family: 'Sharp Grotesk Medium 25';
    font-size: 70px;
    letter-spacing: -1.17px;
    line-height: 70px;
    margin-bottom: 32px;
  }
  p {
    height: 90px;
    width: 262px;
    color: #000000;
    font-family: Karla;
    font-size: 24px;
    letter-spacing: -0.75px;
    line-height: 28px;
  }

  &--phone {
    height: 540px;
    width: 253px;
  }
  &--store {
    height: 36px;
    width: 120px;
    &:first-child {
      margin-right: 11px;
    }
  }
}

.w-btn-signup-responsive {
  width: 100px !important;
  height: 50px !important;
  font-size: 16px !important;
  letter-spacing: -0.8px;
  line-height: 19px;
}
.re-captcha-container {
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  height: 60px;
  width: 100%;
  position: relative;
  .grecaptcha-badge {
    position: relative !important;
    right: unset !important;
    bottom: unset !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}
.modal-create-account {
  .modal-item {
    height: auto !important;
    padding: 20px;
    .screen-code {
      padding-bottom: 150px;
      .digit-code {
        padding-bottom: 20px;
      }
      .step-details {
        margin-bottom: 4px;
      }
    }
  }
  .create-account-btn-group {
    padding-top: 60px;
  }
}
