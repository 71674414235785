@import './modal.scss';

.logo-group {
  font-size: 1rem !important;
  cursor: pointer;
}
.sub-login-title {
  padding: 30px 0px 0px;
  font-size: 30px;
  font-weight: 900;
  letter-spacing: -1px;
  line-height: 28px;
}

.exist-account {
  padding: 5px 0px 15px 0px;
  font-size: 16px !important;
  letter-spacing: -0.67px !important;
  line-height: 19px !important;
}

.btn-accept {
  margin-top: 40px;
  width: 252px;
}

.digit-code {
  padding-top: 0px;
  padding-bottom: 50px;
  font-size: 30px !important;
  font-weight: 800;
  letter-spacing: -1.25px;
  line-height: 36px;
}

.btn-signup {
  font-size: 20px;
  width: 164px;
}

.step-details {
  height: 19px;
  width: 126.06px;
  color: #000000;
  font-family: Karla;
  font-size: 16px;
  font-weight: 800;
  letter-spacing: -0.5px;
  line-height: 19px;
}

.otp-code-title {
  height: 19px;
  width: 521.77px;
  color: #000000;
  font-family: Karla;
  font-size: 16px;
  letter-spacing: -0.67px;
  line-height: 19px;
}

.otp-form {
  width: 70%;
  margin: auto !important;
}

.otp-resend {
  width: 70% !important;
  margin: 5px auto !important;
}

.terms-service {
  margin-top: 180px !important;
  font-size: 9px !important;
  letter-spacing: -0.38px;
  line-height: 10px;
}

.btn-accept {
  margin-top: 10px;
}
