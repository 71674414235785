.your-card-container {
  .top-notification-banner {
    margin-right: 0px;
    margin-bottom: 15px;
    padding: 15px;
    height: fit-content;
    .txt-link-more {
      padding-top: 0px !important;
      text-align: left;
      display: flex;
      flex-direction: column;
    }
    .view-custom-btn {
      margin-top: 14px;
      margin-left: 0px !important;
      width: fit-content;
    }
  }
  .slider-recommanded-grp {
    .bnt-next {
      display: none;
    }
  }
  .control-group-slider {
    margin-top: -80px;
    width: 110vw;
    margin-left: 0px;
    height: 100px;
  }
  .slider-recommanded-grp {
    .carousel {
      .slider {
        @media screen and (min-width: 410px) {
          padding-right: 10px;
        }
      }
      .slide {
        padding-left: 10px;
        width: fit-content;
        @media screen and (max-width: 600px) {
          &:first-child {
            margin-left: 25px;
          }
        }
        @media screen and (max-width: 375px) {
          min-width: 300px !important;
        }
        img {
          width: 100%;
        }
      }
      .slide.selected {
        // min-width: 320px !important;
        @media screen and (max-width: 375px) {
          min-width: 300px !important;
        }
      }
    }
    .container-img-card {
      padding: 0;
    }
  }
  .content-analytic-card {
    transform: translateY(-60px);
    z-index: 2;
  }
  .best-card {
    width: fit-content;
    padding: 3px 10px;
  }
  .card-category-recommended {
    height: unset !important;
    .recommended-your-image-card {
      height: 32px;
      width: auto;
    }
  }
  .card-category-recommended-details {
    .recommended-your-image-card {
      height: 32px;
      max-width: 32px;
      object-fit: contain;
    }
  }
  .additional-benefits {
    margin-top: 24px;
  }
  .carousel {
    .slide {
      img.img-card-slide {
        opacity: 0.5;
      }
    }
    .slide.selected {
      img.img-card-slide {
        opacity: 1 !important;
      }
    }
  }
  .one-card-container-wrapper {
    width: 100% !important;
    margin-left: 0px !important;
    .reward-container {
      padding-left: 15px !important;
    }
    .carousel-slider {
      width: 100% !important;
      .slider {
        padding-right: 0px;
        .slide {
          padding-left: 1px !important;
          padding-right: 1px !important;
          margin-left: 0 !important;
          width: 100%;
        }
      }
    }
    .control-group-slider {
      width: 100%;
    }
  }
}
