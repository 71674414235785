// Web Larg Screen
@media only screen and (min-width: 1367px) {
  @import './web-app-larg-screen/index.scss';
}

// Web small Screen
@media only screen and (max-width: 1366px) and (min-width: 767px) {
  @import './web-app-small-screen/index.scss';
}

// Tablet
// @media only screen and (max-width: 1024px) {
//   @import './tablet-app/index.scss';
// }

// Mobile Larg Screen
@media only screen and (max-width: 767px) and (min-width: 321px) {
  @import './mobile-app-large-screen/index.scss';
}

// Mobile Small Screen
@media only screen and (max-width: 320px) {
  @import './mobile-app-small-screen/index.scss';
}

// Global Style
@import './global.scss';

//global components

@import './components/card.scss';
@import './components/inputCustom.scss';
@import './components/commom.scss';
@import './components/switch.scss';

// Import from npm modules
@import '~animate.css';

@import '~pretty-checkbox/src/scss/variables';

$pretty--colors: (
  success: #000000,
);
@import '~pretty-checkbox/src/pretty-checkbox.scss';

// Font face load

@font-face {
  font-family: 'Sharp Grotesk Medium 25';
  src: url('./fonts/SharpGroteskMedium25-Regular.eot');
  src: url('./fonts/SharpGroteskMedium25-Regular.eot?#iefix')
      format('embedded-opentype'),
    url('./fonts/SharpGroteskMedium25-Regular.woff') format('woff'),
    url('./fonts/SharpGroteskMedium25-Regular.ttf') format('truetype'),
    url('./fonts/SharpGroteskMedium25-Regular.svg#Cerebri Medium') format('svg');
  font-weight: normal;
  font-style: normal;
}

html,
body {
  height: 100%;
  width: 100%;
  -webkit-overflow-scrolling: touch !important;
  position: relative;
  overflow: hidden;
  height: 100%;
  color: #000000;
  // position: fixed;
}

html {
  height: 100vh;
}

body {
  font-family: 'Karla', sans-serif;
  background-color: white;
}

body.fontLoaded {
  font-family: 'Karla', sans-serif;
}

p,
label {
  line-height: 1.5em;
}

input,
select,
button {
  font-family: 'Karla', sans-serif;
  font-size: inherit;
}

.icon {
  width: 1.5rem;
  height: 1.5rem;
}

.nowrap {
  white-space: nowrap;
}
