.my-profile-wrapper {
  padding-top: 90px;
  display: flex;
  flex-direction: column;

  #my-profile-container {
    padding-top: 0 !important;
    display: flex;
    flex-direction: column;
    .btn-select {
      position: absolute;
      left: 31px;
    }
    .menu-profile-wrapper {
      flex-grow: 1;
    }
    .header-back-grp {
      margin-left: 0px;
      margin-top: 0px;
      padding-top: 43px;
      padding-left: 31px;
      padding-right: 31px;
      .title-selected {
        font-weight: 800;
      }
    }
  }
  .menu-profile-wrapper {
    .content-menu-categories {
      .arrow-right {
        color: Black;
        font-weight: 800;
      }
      margin-top: 55px;
      margin-bottom: 146px;
      .box-left {
        margin-left: auto;
        margin-right: 0;
        padding-right: 32px;
      }
      .box-right {
        margin-left: 0;
        padding-left: 32px;
        .category-item {
          margin-left: 0;
          margin-right: auto;
        }
      }
      .category-item {
        display: flex;
        justify-content: space-between;
        padding: 24px 0px;
        border-bottom: 1px solid #999999;
        // max-width: 327px;
        margin-left: auto;
        margin-right: 0;
        .arrow-right-icon {
          height: 24px;
          width: auto;
        }
        &:first-child {
          padding-top: 0px;
        }
        &:last-child {
          padding-bottom: 0px;
          border-bottom: none;
        }
        .category-menu-name {
          font-weight: bold;
          color: black;
        }
        .sign-out-name {
          color: #3f52c7;
        }
      }
    }
  }

  .profile-link-account-wrapper {
    flex-grow: 1;
    .link-account-content {
      margin-top: 15px;
      margin-bottom: 60px;
    }
    .box-left {
      padding-right: 24px;
    }
    .box-right {
      padding-left: 24px;
    }
    .box-categories-only {
      padding-left: 15px;
    }
    .box-link-content {
      .text-label-box {
        font-size: 30px;
        line-height: 36px;
        letter-spacing: -1.25px;
        font-weight: 800;
        margin-bottom: 0;
      }
      .text-des-box {
        margin-top: 16px;
        margin-bottom: 24px;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: -0.67px;
      }
      .icon-check {
        height: 28px;
        width: 28px;
        margin-right: 8px;
      }
    }
    .card-item-link {
      border-radius: 12px;
      border: 1px solid #e8e8e8;
      min-height: 79px;
      padding: 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 16px;
      &.relink {
        background-color: #ccc;
      }
      &:last-child {
        margin-bottom: 0px;
      }
      .right-card {
        color: #3f52c7;
        font-weight: 800;
      }

      .box-img {
        width: 75px;
        .card-img {
          height: 47px;
          width: auto;
          max-width: 75px;
          object-fit: cover;
        }
      }

      .card-name {
        margin-left: 15px;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: -0.5px;
      }

      .card-btn {
        cursor: pointer;
      }
    }
  }
  .my-profile-info-wrapper {
    .content-account-info {
      margin-bottom: 100px;
    }
    .header-back-grp {
      display: flex;
      justify-content: space-between;
      .right-text {
        color: #3f52c7;
        font-weight: bold;
      }
    }
    .container-account-info {
      margin-top: 55px;
      .box-info {
        margin-bottom: 33px;
        .form-info-label {
          margin-bottom: 4px;
          color: #666666;
          font-size: 12px;
          line-height: 14px;
          letter-spacing: -0.5px;
        }
        .form-info-value {
          margin-bottom: 0px;
        }
      }
    }
    .link-delete {
      .form-info-label {
        color: #3f52c7;
        font-family: Karla;
        font-size: 12px;
        letter-spacing: -0.5px;
        line-height: 19px;
      }
    }
  }
  .update-profile-info-wrapper {
    .content-account-info {
      margin-bottom: 0px;
    }
    .box-info {
      padding-left: 28px;
      padding-right: 28px;
    }
    .box-bnt-submit {
      margin-top: 7px;
      margin-bottom: 80px;
      margin-left: auto;
      margin-right: auto;
      .bnt-submit {
        height: 56px;
        width: 223px;
        background-color: #3f52c7;
        border-radius: 28px;
        color: white;
        box-shadow: none;
        outline: none;
        border: none;
      }
    }
  }
  .privacy-wrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    .container-privacy {
      .box-notification {
        font-weight: 800;
        font-size: 30px;
        line-height: 36px;
        letter-spacing: -1.25px;
      }
      .box-switch {
        margin-left: 135px;
        display: flex;
        align-items: center;
        .text-label-box {
          margin-right: 38px;
        }
      }
    }
    .container-privacy {
      margin-top: 137px;
      margin-bottom: 242px;
    }
    .content-account-info {
      display: flex;
      justify-content: center;
    }
  }
  .help-wrapper {
    .text-box-section {
      font-weight: 800;
      font-size: 30px;
      line-height: 36px;
      letter-spacing: -1.25px;
      margin-bottom: 8px;
    }
    .box-left {
      @media (max-width: 767px) {
        padding-right: 15px !important;
      }
      .category-item {
        margin-left: 0;
      }
    }
    .category-item {
      width: 100%;
      max-width: 100% !important;
      @media (max-width: 767px) {
        width: 100% !important;
      }
    }
    .box-right {
      @media (max-width: 767px) {
        margin-top: 41px;
        padding-left: 15px !important;
      }
    }
  }
  .customer-wrapper {
    .category-item {
      width: 100% !important;
      max-width: 100%;
      &:last-child {
        border-bottom: 1px solid #999999 !important;
        padding-bottom: 24px !important;
      }
    }
    .collapse-penal {
      margin-top: 16px;
    }
    .container-menu-categories {
      .content-menu-categories {
        margin-top: 0px;
        .box-categories {
          margin-top: 55px;
        }
      }
    }
  }
  .acknowledgement-wrapper {
    .content-menu-categories {
      margin-top: 15px;
    }
  }
  .contact-us-wrapper {
    .container-contact {
      padding-left: 0px;
      padding-right: 0px;
      .box-text-top {
        margin-top: 24px;
        text-align: left;
        padding-left: 0;
      }
      .content-menu-categories {
        padding-left: 0px;
        padding-right: 0px;
      }
      .text-message {
        font-weight: 800;
        margin-top: 24px;
        text-align: left;
        color: #333333;
      }
      .container-text {
        text-align: left;
        padding-left: 1px;
        .text-contact {
          margin-top: 8px;
          width: 100%;
          min-height: 200px;
          border-radius: 12px;
          padding: 15px 21px;

          &:focus {
            outline: none;
          }
        }
      }
      .text-count {
        font-size: 12px;
        line-height: 14px;
        color: #666666;
        margin-bottom: 0px;
      }
      .bnt-send {
        width: 223px;
        height: 56px;
        background: #3f52c7;
        color: #fff;
        border-radius: 28px;
        margin-bottom: 55px;
        margin-top: 30px;

        &:disabled {
          pointer-events: none;
          opacity: 0.5;
        }
      }
    }
    .text-thank-you {
      margin-top: 40px;
      font-size: 30px;
      line-height: 36px;
      letter-spacing: -1.5px;
      font-weight: 800;
    }
    .box-img-success-message {
      margin-top: 48px;
      margin-bottom: 48px;
      img {
        height: 150px;
        width: 150px;
      }
    }
    .text-des-thank {
      max-width: 260px;
      text-align: center;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 21px;
    }
  }
  .delete-account-wrapper {
    .form-submit-text-wrapper {
      .box-text-top {
        margin-top: 16px;
        p {
          display: inline;
        }
      }
      .text-message {
        margin-top: 40px;
      }
      .container-text {
        .text-contact {
          min-height: 211px;
        }
      }
      .box-warning {
        margin-top: 24px;
        align-items: center;
        &__checkbox {
          transform: scale(1.7);
        }
        &__text {
          margin-left: 16px;
          font-size: 9px;
          line-height: 10px;
          max-width: 346px;
          text-align: left;
        }
        &__des {
          max-width: 382px;
        }
      }
    }
    .ask-confirm-wrapper {
      margin-bottom: 115px;
      .title-confirm {
        color: #000000;
        font-family: Karla;
        font-size: 30px;
        font-weight: 800;
        letter-spacing: -1.25px;
        line-height: 36px;
        text-align: center;
        margin-top: 136px;
      }
      .button-group {
        margin-top: 40px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .bnt-notification {
          padding: 18px 40px;
          background-color: #3f52c7;
          border-radius: 28px;
          width: fit-content;
          color: #fff;
          font-family: Karla;
          font-size: 16px;
          letter-spacing: -0.8px;
          line-height: 19px;
        }
        .bnt-continue {
          margin-top: 24px;
          color: #3f52c7;
          font-family: Karla;
          font-size: 16px;
          font-weight: bold;
          letter-spacing: -0.67px;
          line-height: 19px;
        }
      }
    }
    .validate-otp-wrapper {
      margin-bottom: 135px;
      .title-confirm {
        margin-top: 100px;
        max-width: 500px;
        margin-left: auto;
        margin-right: auto;
      }
      .box-otp {
        .otp-form {
          padding-top: 55px !important;
        }
        .otp-resend {
          text-align: left;
        }
      }
    }
    .finish-wrapper {
      margin-bottom: 160px;
      .title-finish {
        color: #000000;
        font-family: Karla;
        font-size: 30px;
        font-weight: 800;
        letter-spacing: -1.25px;
        line-height: 36px;
        text-align: center;
        max-width: 564px;
        margin-left: auto;
        margin-right: auto;
        margin-top: 140px;
      }
      .result-description {
        color: #000000;
        font-family: Karla;
        font-size: 16px;
        letter-spacing: -0.67px;
        line-height: 19px;
        max-width: 438px;
        margin-top: 16px;
        margin-left: auto;
        margin-right: auto;
        text-align: left;
      }
    }
  }
}
