.modal-confirm-card-link {
  font-size: 16px;
  max-width: 592px !important;

  .modal-content {
    height: 100%;
    min-height: 445px;
    max-height: 640px;
  }

  .modal-view {
    height: 100%;
    min-height: 445px;
    position: relative;
  }

  .modal-body {
    height: 100%;
    min-height: 445px;
    padding: 40px;
    @media (max-width: 425px) {
      padding: 25px;
    }
  }

  .modal-btn-close {
    font-size: 24px;
    position: absolute;
    top: 10px;
    right: 10px;

    border: none;
    background-color: #fff;
    width: 30px;
    height: 30px;
    line-height: 30px;
    cursor: pointer;
  }

  &__content {
    height: 100%;
    min-height: 445px;
  }

  &__title {
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 5px;
    @media (max-width: 425px) {
      font-size: 24px;
    }
  }

  &__message {
    margin-bottom: 24px;
  }
  &__note {
    margin-bottom: 15px;
  }
  &__list {
    max-height: 280px;
    overflow-y: auto;
    margin-bottom: 15px;
  }
  &__card {
    display: inline-block;
    width: 325px;
    height: 79px;
    padding: 16px;
    margin-bottom: 16px;
    border-radius: 12px;
    border: 1px solid #e8e8e8;
    @media (max-width: 425px) {
      width: 100%;
    }
  }

  .card-inner {
    padding-right: 5px;
  }

  .card-img {
    width: 77px;
    height: 49px;

    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 75px;
      height: 47px;
    }
    &.border {
      border: 1px solid #e8e8e8;
    }
  }
  .card-name {
    margin: 0 15px;
  }
  .card-icon {
    width: 24px;
    height: 24px;
  }
  &__notice {
    margin-bottom: 30px;
  }
  &__btn {
    width: 223px;
    height: 56px;
    font-weight: bold;
    color: #fff;
    text-align: center;
    line-height: 56px;
    margin: 0 auto;
    border-radius: 28px;
    cursor: pointer;
    background-color: #3f52c7;
  }

  &__btn-back {
    width: 223px;
    margin: 0 auto;
    margin-top: 25px;
    border-radius: 28px;
    font-weight: bold;
    color: #3f52c7;
    text-align: center;
    cursor: pointer;
  }
}
