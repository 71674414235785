.recommend-card-steps-container {
  .personalized-more {
    // max-width: 450px;
  }
  .reward-container {
    max-width: 630px;
    margin-left: auto;
    margin-right: auto;
  }
  .text-expand {
    color: #3f52c7;
    font-size: 12px;
    font-weight: 600;
  }

  .container-slide-expand {
    .recommendation-slide-grp {
      margin-bottom: 16px;
      background: transparent;
      border-radius: 12px;
      &:not(:first-child) {
        .recommendation-custom-content-summary {
          border-radius: 12px;
        }
      }
    }
  }
  .main-card-large {
    width: 125px !important;
    height: auto !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    margin-right: 21px !important;
  }
  .full-name-card {
    font-size: 20px;
    line-height: 23px;
    letter-spacing: -0.62px;
  }
  .recommendation-custom-content-summary-detail {
    .card-small {
      height: 40px !important;
      width: auto !important;
    }
    .next-ico-reward-grp {
      bottom: 35px;
    }
  }
  .card-small {
    height: 40px !important;
    width: auto !important;
  }
  .recommendation-custom-content-summary-small {
    height: 140px !important;
  }
  .more-link {
    color: #3f52c7;
  }
  .reward-end-container {
    margin-bottom: 40px;
  }
  .recommendation-custom-content-summary-large {
    height: 185px !important;
  }
  .reset-position-flex {
    .next-ico-reward-grp {
      position: absolute !important;
      bottom: 18px;
    }
  }
}
.custom-recommendation-container {
  .img-item-custom-recommendation {
    max-height: 40px;
    width: auto !important;
    height: auto !important;
  }
}
