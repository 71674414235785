.HomeAccessMobile-wrapper {
  height: 100vh;
  overflow-x: auto;
  .HomeAccessMobile-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    min-height: 75px;
  }
  .HomeAccessMobile-body {
    .HomeAccessMobile-qrcode-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .notify-text {
        color: #000000;
        font-size: 20px;
        letter-spacing: -1.2px;
        line-height: 23px;
        padding-left: 60px;
        span {
          font-weight: 600;
        }
      }
      .qr-wrapper {
        box-sizing: border-box;
        height: 83px;
        width: 270px;
        border: 1px solid #e8e8e8;
        border-top-left-radius: 12px;
        border-bottom-left-radius: 12px;
        background-color: #ffffff;
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.1);
        padding: 14px 24px;

        display: flex;
        align-items: center;
        justify-content: space-between;

        .qr-text {
          font-size: 12px;
          letter-spacing: -0.72px;
          line-height: 14px;
        }
      }
    }
    .HomeAccessMobile-contents {
      padding-top: 70px;
      padding-bottom: 90px;
      display: flex;
      align-items: center;
      justify-content: center;
      .HomeAccessMobile-card {
        box-sizing: border-box;
        height: 342px;
        width: 330px;
        border: 1px solid #e8e8e8;
        border-radius: 12px;
        background-color: #ffffff;
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.1);
        padding: 30px;

        display: flex;
        flex-direction: column;
        justify-content: space-between;

        &:nth-child(2) {
          margin-left: 20px;
        }
        .img-wrapper {
          height: 56px;
          width: 56px;
          padding: 8px;
          border-radius: 12px;
          background-color: #ffffff;
          box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.2);
          margin: 0 auto;
          img {
            width: 42px;
            height: 42px;
          }
        }
        .img-wrapper-no-box-shadow {
          height: 56px;
          width: 56px;
          padding: 8px;
          border-radius: 12px;
          background-color: #ffffff;
          margin: 0 auto;
          img {
            width: 70px;
            height: 70px;
          }
        }
        .card-text {
          font-size: 16px;
          letter-spacing: -0.96px;
          line-height: 19px;
          text-align: center;
          margin-top: 16px;
        }
        input {
          width: 100%;
          border: none;
          font-size: 20px;
          font-weight: 800;
          letter-spacing: -0.47px;
          line-height: 26px;
          color: #000000;
          box-shadow: none;
          background-color: #fff;

          &:focus-visible {
            outline: none;
          }
        }
        .line {
          width: 100%;
          height: 1px;
          background-color: #999999;
        }
        label {
          font-size: 12px;
          letter-spacing: -0.5px;
          line-height: 14px;
        }
        .btn-card {
          font-size: 16px;
          font-weight: bold;
          letter-spacing: -0.8px;
          line-height: 19px;
          text-align: center;
          color: #ffffff;
          text-align: center;
          display: block;
          margin: 0 auto;
          width: 220px;
        }
        .how-it-work {
          display: flex;
          align-items: center;
          justify-content: center;

          cursor: pointer;
          .play-btn {
            height: 25px;
            width: 25px;
            border-radius: 50%;
            background-color: #3f52c7;

            display: flex;
            align-items: center;
            justify-content: center;
          }
          .how-it-work-text {
            font-size: 16px;
            font-weight: bold;
            letter-spacing: -0.8px;
            line-height: 19px;
            color: #3f52c7;

            margin-left: 10px;
          }
        }
      }
    }
  }
  .triangle {
    width: 0;
    height: 0;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;

    border-left: 8px solid #ffffff;
  }
  .err {
    font-size: 12px;
    letter-spacing: -0.5px;
    line-height: 14px;
    color: red;
  }
}
