.extension-thank-wrapper {
  background-color: #fafafa;
  height: 100vh;
  overflow: scroll;
  &--header {
    position: relative;
    background-color: rgb(255, 255, 255);
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
    box-shadow: rgba(0, 0, 0, 0.03) 0px 2px 9px 0px;
    display: flex;
    height: 80px;
    justify-content: center;
    text-align: center;
    width: 100%;
  }
  &--header-logo-wrapper {
    display: flex;
    align-items: center;

    position: relative;
  }

  &--body {
    border: solid 1px rgba(0, 0, 0, 0.06);
    padding: 47px;
    box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.02);
    margin-top: 27px;
    margin-left: 64px;
    margin-right: 64px;
    border-radius: 2px;
    margin-bottom: 47px;
    background-color: #ffffff;

    .carousel-wrapper {
      width: 895px;
      margin: 0 auto;
      .carousel-root {
        // background-color: #fafafa;
        .carousel-slider {
          width: 100% !important;
          height: 100% !important;
          padding-left: 30px !important;
          padding-right: 30px !important;
          .slide-item {
            width: 630px;
            height: 330px;
          }
          .control-dots {
            left: 0 !important;
            .dot {
              background: rgba(0, 0, 0, 0.3);
            }
            .dot.selected {
              background: #000000;
            }
          }
        }
      }
    }
  }
  &--body-title {
    h1 {
      font-size: 28px;
      text-align: center;
      font-weight: bold;
      margin-bottom: 40px;
    }
    h5 {
      color: #616161;
      font-size: 18px;
      text-align: center;
      margin-bottom: 41px;
    }
  }

  &--body-bottom {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    margin-top: 40px;
  }

  .extension-thank-wrapper--body-bottom-text {
    margin-bottom: 40px;

    font-size: 16px;
    font-weight: bold;
  }

  .icon-slide {
    width: 30px;
  }

  .arrow-icon-pre {
    position: absolute;
    top: 50%;
    left: 0;
    z-index: 9999;
    cursor: pointer;
  }

  .arrow-icon-next {
    position: absolute;
    top: 50%;
    right: 0;
    cursor: pointer;
  }

  .extension-thank--btn {
    border-color: #3f52c7;
    background-color: #3f52c7;

    cursor: pointer;
    height: 32px;
    outline: none;
    padding: 0px 12px;
    background: none;
    box-shadow: none;
    transition: all 0.2s ease 0s;
    text-shadow: none;
    user-select: none;
    border-style: solid;
    border-width: 1px;
    border-radius: 3px;
    font-size: 14px;
    background: #3f52c7;
    color: #fff;

    img {
      width: 12px;
      margin-right: 10px;
    }
  }
}
