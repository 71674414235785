.earned-reward-wrapper {
  .spent-title,
  .rewards-title {
    font-size: 12px;
    line-height: 16px;
  }
}
.earned-reward-item-wrapper,
.missed-reward-item-wrapper {
  .spent-net-container {
    margin-right: 25px !important;
  }
  .recommend-grp {
    .reward-details-content-summary {
      .card-details {
        padding: 0 16px !important;
      }
    }
  }
  .top-card-container {
    .card-details {
      padding-left: 0 !important;
    }
    .reward-item {
      .card-name {
        font-size: 16px;
        height: 19px;
        letter-spacing: -0.5px;
      }
      .spent-title,
      .rewards-title {
        font-size: 12px;
        line-height: 16px;
      }
    }
  }
  .reward-details-content-summary {
    height: 121px !important;
    padding: 16px !important;
    .card-name {
      font-size: 12px;
      height: 14px;
      letter-spacing: -0.37px;
    }
    .card-details {
      bottom: 16px !important;
    }
    .spent-title,
    .rewards-title {
      font-size: 12px;
      line-height: 16px;
    }
    .rewards-price,
    .spent-price {
      font-size: 16px;
      line-height: 19px;
    }
    .rw-card-grp-earned-spent-reward {
      margin-right: 25px !important;
      margin-left: auto !important;
    }

    .spent-item,
    .rewards-item {
      width: fit-content;
      min-width: auto;
    }
    .spent-title,
    .spent-price {
      width: fit-content;
      margin-right: auto;
      margin-left: 0;
    }
    .rewards-title,
    .rewards-price {
      width: fit-content;
    }

    .border-right {
      margin-left: 12px !important;
      margin-right: 12px !important;
    }
    .img-card-grp-earned {
      align-items: flex-end;
      display: flex;
      img.img-earned-add {
        width: 23px !important;
        height: 23px !important;
        margin-left: 8px;
      }
      img {
        height: 47px !important;
        width: auto;
        bottom: 0px;
      }
    }
  }

  .reward-item-missed {
    height: 130px !important;
    .spent-title-missed,
    .rewards-title {
      font-size: 12px;
      line-height: 14px;
    }
    .spent-item-missed,
    .rewards-item-missed {
      width: fit-content;
      min-width: auto;
    }
    .spent-title-missed,
    .spent-price-missed {
      width: fit-content;
      margin-right: 0;
      margin-left: auto;
    }
    .rewards-title,
    .rewards-price {
      width: fit-content;
    }
    .spent-title,
    .rewards-title {
      font-size: 12px;
      line-height: 16px;
    }
    .border-right {
      margin-left: 12px !important;
      margin-right: 12px !important;
      padding: 0 !important;
      flex: 0 !important;
      max-width: 1px !important;
    }
  }
}

.reward-item {
  .card-details {
    .custom-divider-2 {
      margin-right: 12px !important;
      margin-left: 12px !important;
      padding-right: 0;
      padding-left: 0;
    }
  }
}

.custom-spent-item,
.custom-rewards-item {
  min-width: auto !important;
  width: fit-content !important;
}
