.container-page {
  .recommend-card-detail-container {
    .earned-details {
      font-size: 30px;
      line-height: 36px;
      letter-spacing: -1.8px;
      text-align: center !important;
    }
    .recommended-card-details {
      padding-top: 0px !important;
    }
    .box-card-info {
      flex-direction: column;
    }
    .content-card-detail {
      margin-top: 45px !important;
    }
    .card-name {
      margin-left: 0px !important;
      .card-full-name {
        font-size: 16px;
        line-height: 19px;
        letter-spacing: -0.5px;
        margin-top: 16px !important;
        margin-bottom: 12px;
      }
    }
    .card-logo-im {
      width: 118px;
      height: auto;
    }
    .line-item-detail {
      .card-recommed-categrogy {
        font-size: 12px;
        line-height: 14px;
      }
      .based-on-your-last {
        padding-top: 12px;
        padding-bottom: 16px;
      }
      .small-title {
        padding-top: 12px !important;
        font-size: 12px;
        line-height: 14px;
      }
    }
    .line-item-detail-end {
      border-top: 1px solid #cccccc;
      margin-top: 1.5rem;
    }
    .based-excellent {
      padding-top: 40px !important;
      img {
        margin-right: 12px;
      }
    }
    .bnt-normal-size {
      width: 223px;
    }
    .card-logo-img {
      width: 184px;
      height: auto !important;
    }
  }
}

.modal-changeApproval-mobile{
  @media only screen and (max-width: 768px){
    .modal-content{
      min-height: 420px !important;
    }
  }
}