.recommend-card-steps-container {
  .text-expand {
    color: #3f52c7;
    font-size: 12px;
    font-weight: 600;
  }

  .container-slide-expand {
    .recommendation-slide-grp {
      margin-bottom: 16px;
      background: transparent;
      border-radius: 12px;
      &:not(:first-child) {
        .recommendation-custom-content-summary {
          border-radius: 12px;
        }
      }
    }
  }
  .reset-position-flex {
    .next-ico-reward-grp {
      position: absolute !important;
      bottom: 18px;
      right: 0;
    }
  }
  .carousel-root {
    .carousel-slider {
      overflow: visible;
      .slider-wrapper {
        overflow: visible;
      }
    }
  }
  .list-grp {
    .reward-container {
      font-size: 12px;
      padding-left: 15px !important;
      padding-right: 15px !important;
    }
  }
  .border-center-item {
    margin-left: -10px;
  }
}
