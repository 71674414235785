.earned-reward-wrapper {
  .reward-details-content-summary {
    .img-card-grp-earned {
      align-items: flex-end;
      display: flex;
      img.img-earned-add {
        width: 23px !important;
        height: 23px !important;
        margin-left: 8px;
      }
      img {
        height: 47px !important;
        width: auto;
      }
    }
    .spent-title,
    .rewards-title {
      font-size: 12px;
      line-height: 14px;
    }
  }
  .card-details {
    position: absolute;
    bottom: 20 px;
    left: 0px;
    right: 0px;
    padding-left: 16px !important;
    padding-right: 16px !important;
    justify-content: space-between;
    .rw-card-grp-earned {
      padding: 0;
      margin: 0;
    }
  }
  .rw-card-grp-earned-spent-reward {
    margin-right: 25px !important;
    .spent-item,
    .rewards-item {
      width: fit-content;
      min-width: auto;
    }
    .spent-title,
    .spent-price {
      width: fit-content;
      margin-right: auto;
      margin-left: 0;
    }
    .rewards-title,
    .rewards-price {
      width: fit-content;
    }
    .border-right {
      margin-left: 12px;
      margin-right: 12px;
    }
  }
}

.recommend-grp {
  .custom-height-more-earned-card-mobile {
    height: 145px !important;
  }
}
