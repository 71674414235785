$header-height: 90px;
$footer-height: 165px;

body {
  // overflow: scroll !important;
  .custom-home {
    .group-date-main {
      margin-top: 32px;
      //  margin-bottom: 74px;
    }
  }
  .header {
    position: absolute;
    top: 0px;
    width: 100%;
    height: $header-height;
    z-index: 3;
    background: white;
  }

  .footer-item-grp {
    max-height: $footer-height;
    height: $footer-height;
    padding-top: 10px;
    // position: fixed;
    // width: 100%;
    background-color: #3f52c7;
    border-top: 1px solid #cccccc !important;
    align-items: unset;
    // position: unset;
  }

  .container-page {
    height: 100vh !important;
    overflow: hidden !important;
  }
  .reward-grp-page {
    // height: calc(100% - 165px) !important;
    overflow-y: auto !important;
    overflow-x: hidden !important;
    // padding-top: 12vh !important;
    // position: relative;
  }

  .reward-home-page {
    overflow-y: auto !important;
    overflow-x: hidden !important;
  }
  .your-rewards-summary-mobile {
    display: none;
  }
}

.content-link-account {
  font-size: 16px !important;
  .now-link-your-credit {
    height: 19px;
    width: 355px;
    color: #000000;
    font-size: 16px;
    letter-spacing: -0.67px;
    line-height: 19px;
    text-align: center;
  }
}

.img-rewards {
  height: 60px;
  width: 53px;
  margin-right: 20px;
}

.btn-link-account {
  height: 55px;
  width: 226px;
  margin-top: 15px;
}

.link-account-group {
  width: 100%;
}

// New Reward
.container-page {
  .reward-content-text {
    font-family: 'Sharp Grotesk Medium 25';
  }
  // .img-gift {
  //   height: 90px;
  //   width: auto;
  // }
  .reward-container {
    .reward-content-summary:focus {
      outline: none;
    }
    .reward-content-summary {
      cursor: pointer;
      height: 163px;
      border: 1px solid #e8e8e8;
      border-radius: 12px;
      background-color: #ffffff;
      box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.1);

      .money-summary {
        font-family: 'Sharp Grotesk Medium 25';
        font-size: 22px;
        letter-spacing: 0;
        line-height: 28px;
        text-align: left;
        margin: 20px 0px;
        margin-top: 0px;
        text-align: center;
      }
    }

    .reward-rectangle {
      background-color: #3f52c7;
      color: #ffffff;
    }

    // .personalized-content {
    //   position: absolute;
    //   bottom: 14%;
    // }

    .earned-summary {
      //  margin-top: auto;
      //  margin-bottom: 20px;
      display: flex;
      flex-direction: column-reverse;
      .reward-content-summary {
        height: 342px;

        // position: absolute;
        // bottom: 40%;
      }
    }

    .missed-summary-rectangle {
      //  margin-top: auto;
      //  margin-bottom: 20px;
      display: flex;
      flex-direction: column-reverse;
      .reward-content-summary {
        height: 163px;
        // margin-bottom: 16px;
        flex: 0 0 50%;
      }
    }

    .title-card-reward {
      position: absolute;
      bottom: 20px;
      left: 20px;
    }

    .reward-details-content-summary {
      height: 168px;
      border: 1px solid #e8e8e8;
      cursor: pointer;
      border-radius: 12px;
      background-color: #ffffff;
      padding: 20px;
      box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.1);
    }
    .card-name {
      font-size: 12px;
      letter-spacing: -0.38px;
      line-height: 14px;
    }

    .card-details {
      position: absolute;
      bottom: 20px;
      left: 0px;
      right: 0px;
      padding-left: 16px !important;
      padding-right: 16px !important;
      justify-content: space-between;
    }

    .spent-item {
      font-size: 15px;
      letter-spacing: -0.38px;
      line-height: 19px;
      margin-top: 16px;
      width: 70px;
      // border-right: 1px solid #cccccc !important;
      // padding-right: -20px;
    }

    .spent-item-missed {
      font-size: 16px;
      letter-spacing: -0.38px;
      line-height: 19px;
      margin-top: 32px;
    }

    .rewards-item-missed {
      font-size: 16px;
      letter-spacing: -0.38px;
      line-height: 19px;
      margin-top: 32px;
    }

    .border-right {
      border-right: 1px solid #cccccc !important;
      // height: 28px;
      margin-top: 20px;
      width: 1px;
      // margin-left: -20px;
      margin-right: 30px;
    }

    .border-right-missed {
      margin-top: 36px;
    }
    .rewards-item {
      font-size: 16px;
      // font-weight: 800;
      width: 80px;
      letter-spacing: -0.38px;
      line-height: 19px;
      margin-top: 16px;
    }

    .rewards-price {
      letter-spacing: -0.38px;
      line-height: 19px;
      font-weight: 900;
      font-size: 17px;
    }

    .date-time-select-reward {
      border: none !important;
      text-align: center;
      padding-top: 5px !important;
    }
    .date-time-select-reward:focus {
      outline: none !important;
    }

    .rw-card-details {
      padding-top: 20px;

      .card-item-ct {
        font-size: 16px;
        color: #000000;
        letter-spacing: -0.67px;
        line-height: 19px;
        padding-top: 10px;
      }
    }

    .reset-flex-basis {
      flex: 0 0 25% !important;
      max-width: 25% !important;
    }

    .next-ico-reward-grp {
      position: absolute;
      bottom: 12px;
      right: 10px;
    }

    .reset-position-flex {
      flex: 0 0 50px;
      margin-right: 60px;
    }

    .earned-details {
      font-size: 30px;
      font-weight: 800;
      letter-spacing: -0.94px;
      line-height: 36px;
      margin-top: 10px;
      padding-bottom: 20px;
      color: #000000;

      .total-cost {
        color: #3f52c7;
        font-size: 30px;
        font-weight: 800;
        letter-spacing: -0.94px;
        line-height: 36px;
      }
    }

    .btn-link-more-account {
      margin-top: 30px !important;
      height: 56px;
      width: 252px;
      margin-top: 15px;
      font-size: 20px;
      letter-spacing: -1px;
      line-height: 23px;
    }

    .reward-item {
      height: 148px;
      // border: 1px solid #e8e8e8;
      border-radius: 4px;
      background-color: #ffffff;
      // padding: 20px;
      // box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.1);
    }
    .reward-item-missed {
      height: 148px;
      // border: 1px solid #e8e8e8;
      border-radius: 4px;
      background-color: #ffffff;
      // padding: 20px;
      // box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.1);
    }
    .total-money-item {
      font-size: 24px;
      font-weight: 800;
      letter-spacing: -1.44px;
      line-height: 28px;
      color: #000000;
    }

    .month-select {
      min-height: 40px;
      padding: 15px;
    }

    .month-select-group {
      margin: auto;
      width: fit-content;
      display: flex;

      .month-input {
        width: 80px;
        margin: 0px !important;
        .date-time-select-reward {
          width: 80px;
          padding: 0px;
        }
      }

      .img-control {
        width: 12px;
        height: 12px;
      }

      .img-control-next {
        width: 15px;
        height: 15px;
      }
    }

    .top-transaction {
      font-size: 24px;
      font-weight: 800;
      letter-spacing: -0.75px;
      line-height: 28px;
    }

    .header-transaction {
      padding-top: 30px;
      padding-bottom: 30px;
      font-size: 12px;
      letter-spacing: -0.38px;
      line-height: 14px;
      .cus-margin {
        margin-right: 10px;
      }
    }

    .title-transaction {
      // font-size: 12px;
      letter-spacing: -0.5px;
      line-height: 14px;
    }
    .sub-title-transaction {
      font-size: 12px;
      letter-spacing: -0.5px;
      line-height: 14px;
      margin-top: 8px;
    }

    .sub-title-transaction-missed {
      font-size: 12px;
      letter-spacing: -0.5px;
      line-height: 14px;
    }

    .out-earning-btn {
      padding: 0px;
      font-size: 12px;
      letter-spacing: -0.5px;
      line-height: 14px;
    }

    .title-welcome {
      font-size: 16px;
      letter-spacing: -0.5px;
      line-height: 19px;
      padding-bottom: 30px;
      padding-top: 10px;
    }

    .total-bunus {
      font-size: 36px;
      font-weight: 300;
      letter-spacing: -2.16px;
      line-height: 42px;
      text-align: center;
    }

    .bonus-details {
      font-size: 12px;
      letter-spacing: -0.38px;
      line-height: 14px;
      text-align: center;
      padding-top: 20px;
      padding-bottom: 30px;
    }

    .welcom-bonus-group {
      border-bottom: 5px solid #cccccc !important;
      margin-bottom: 30px !important;
    }

    .header-back-grp {
      padding-bottom: 25px;
      margin-left: 25px;
      margin-top: 50px;
      button {
        border: none;
        background: none;
        cursor: pointer;
        &:disabled {
          cursor: not-allowed;
        }
      }

      button:focus {
        outline: 0;
      }

      .title-selected {
        font-size: 16px;
        letter-spacing: -0.96px;
        line-height: 19px;
        font-weight: bold;
        margin-left: -100px;
      }

      .img-control-back {
        height: 30px;
      }
    }
    .title-progress {
      font-size: 24px;
      letter-spacing: -0.75px;
      line-height: 28px;
    }
    .cracking-bonus,
    .spend-bonus {
      font-size: 16px;
      letter-spacing: -0.5px;
      line-height: 19px;
      padding-top: 20px;
    }

    .progress-bar-bonus {
      margin-top: 50px;
    }
    .range-step {
      min-width: 80px;
      font-size: 12px;
      letter-spacing: -0.38px;
      line-height: 14px;
    }
    .last-step {
      margin-top: -30px;
    }

    .first-step {
      margin-top: -30px;
      padding-left: 40px;
    }

    .spent-money-total {
      font-weight: 800;
      letter-spacing: -0.38px;
      line-height: 19px;
    }

    .see-more-reward-title {
      font-size: 24px;
      font-weight: 800;
      letter-spacing: -0.75px;
      line-height: 28px;
    }

    .your-reward-title {
      font-size: 16px;
      letter-spacing: -0.96px;
      line-height: 19px;
    }

    .title-smr-reward {
      font-family: 'Sharp Grotesk Medium 25';
      font-size: 22px;
      letter-spacing: 0;
      line-height: 26px;
    }

    .rdtPicker {
      left: -80px;
    }

    .user-name {
      font-size: 16px;
      font-weight: 800;
      letter-spacing: -0.96px;
      line-height: 19px;
    }

    .mb-border {
      display: none;
    }

    .link-more-account-suggest {
      border-radius: 12px;
      background-color: #3f52c7;
      color: #ffffff;
      font-size: 16px;
      letter-spacing: -0.5px;
      line-height: 19px;
      text-align: center;
      margin-top: 30px;
      margin-right: 20px;
      position: relative;
      .txt-link-more {
        padding: 10px !important;
      }
      .link-more-account-question-btn {
        margin-top: 12px;
      }
      .btn-close-suggest {
        position: absolute;
        top: 5px;
        right: 0px;
        font-size: 14px;
        color: #ffffff;
        z-index: 1;
      }
    }

    .no-stats-grp {
      text-align: center;

      .title-no-stats {
        color: #9b9b9b;
        padding-bottom: 40px;
      }
    }
  }
  .month-year-select-grp-mb {
    display: none;
  }

  .amount-tst {
    margin-top: -5px;
  }

  .category-name {
    white-space: nowrap;
    margin-bottom: 25px;
    margin-left: 60px;
  }

  .text-category {
    white-space: normal;
    max-width: 150px;
    font-size: 16px;
    letter-spacing: -0.5px;
    line-height: 19px;
  }

  .details-grp {
    margin-top: 20px;
  }

  .img-item-card {
    width: 40px;
    height: 40px;
    margin-top: 10px;
    position: absolute;
    bottom: 28px;
  }
}

.arrow-progress {
  margin-left: -5px;
}

.progress-money {
  padding-top: 10px;
  position: absolute;
  top: 15px;
  left: -1;
  margin-left: -30px;
}

.mb-select-grp {
  display: none !important;
}
.earned-reward-wrapper {
  .img-card-grp-earned {
    margin-top: 10px;
    align-items: flex-end;
    display: flex;
    img.img-earned-add {
      width: 23px !important;
      height: 23px !important;
      margin-left: 8px;
    }
    img {
      height: 75px !important;
      width: auto !important;
    }
  }
}

.like-ico {
  margin-right: 5px !important;
}

// Tablet small screen
@media only screen and (max-width: 975px) and (min-width: 700px) {
  .container-page {
    .reward-container {
      .recommend-grp {
        padding-right: 0px;
      }
      .spent-item {
        // margin-top: 20px !important;
        font-size: 13px;
        width: 70px;
      }

      .spent-item-missed {
        font-size: 13px;
        width: 50px;
      }

      .text-category {
        white-space: normal;
        max-width: 90px;
      }
      .rewards-item-missed {
        font-size: 13px;
        width: 50px;
      }

      .rewards-item {
        font-size: 13px;
        width: 70px;
      }

      .category-name {
        margin-left: 44px;
      }

      .rewards-price {
        letter-spacing: -0.38px;
        line-height: 19px;
        font-weight: 900;
        font-size: 15px;
      }
      .reward-details-content-summary {
        height: 138px;
      }
    }
    .img-card-grp {
      img {
        width: 40px;
        height: 40px;
      }
    }

    .img-card-grp-earned {
      align-items: flex-end;
      display: flex;
      img.img-earned-add {
        width: 23px !important;
        height: 23px !important;
        margin-left: 8px;
      }
    }

    .border-right {
      margin-right: 5px !important;
      margin-left: -8px !important;
      width: 1px;
    }
    .rw-card-grp {
      padding-left: 5px;
    }

    .rw-card-grp-earned {
      padding-left: 5px;
    }
  }
}

.modal-offer {
  .title-get-earning {
    font-size: 16px;
    line-height: 19px;
  }
  .point-grp {
    position: absolute;
    width: auto;
    // top: 25%;

    .title-get-offer {
      font-size: 32px;
      font-weight: 800;
      letter-spacing: -1.92px;
      line-height: 37px;
      padding-bottom: 40px;
    }

    .update-point {
      font-size: 16px;
      letter-spacing: -0.5px;
      line-height: 19px;
      padding-bottom: 30px;
    }
  }
}

.is-mobile {
  display: none;
}

.update-account-container {
  padding-left: 0px !important;
  padding-right: 10px !important;
}

@media only screen and (max-width: 767px) and (min-width: 599px) {
  .missed-summary {
    padding-left: 0px !important;
    padding-right: 10px !important;
  }
}

.see-our-proprietary {
  font-size: 16px;
  font-weight: 800;
  letter-spacing: -0.67px;
  line-height: 19px;
}

.link-more-account-loader {
  margin-top: 50px;

  .btn-link-more-account {
    height: 56px;
    width: 223px;
    font-size: 16px;
    letter-spacing: -0.8px;
    line-height: 19px;
  }
}

.main-date-select {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  // button:nth-child(1) {
  //   order: 2;
  // }
  // button:nth-child(2) {
  //   order: 1;
  // }

  .btn-month {
    border: 1px solid #d5d5d5;
    border-radius: 100px 0 0 100px;
    background-color: #ffffff;
  }
  .border-radius-left {
    border: 1px solid #d5d5d5;
    border-radius: 0px 100px 100px 0px;
  }
}

// .personalized-content {
//   position: absolute;
//   bottom: 16%;
// }

.reward-summary-custom {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .user-profile {
    min-height: 70px !important;
    flex: 0 0 calc((100% - 358px) / 2 - 80px);
  }
  .reward-custom-d-flex {
    flex: 0 0 358px;
  }
  .group-date-main {
    flex: 0 0 70px;
  }
}

.personalized-content-grp {
  display: flex;
  flex-direction: column;
  .custom-flex {
    margin-top: 80px;
    min-height: 70px !important;
    flex: 0 0 calc((100% - 358px) / 2 - 80px);
    @media screen and (max-width: 767px) {
      display: none;
    }
  }
  .personalized-content {
    margin-top: 16px;
    .spending-item {
      padding-bottom: 16px !important;
    }
    flex: 0 0 358px;
  }
}

.security-text {
  font-size: 12px;
  letter-spacing: -0.5px;
  line-height: 14px;
}

.group-submit-bonus {
  position: absolute;
  justify-content: center;
  text-align: center;
  bottom: 0px !important;
}

.img-item-card-earned-dts {
  margin-top: 10px;
}

.recommended-your-image-card {
  height: 40px;
  width: 80px !important;
}

.recommended-your-image-main-card {
  height: 32px;
  width: 51px !important;
}

.header-back-grp-referral {
  display: none;
}

.title-referral {
  margin-top: 50px;
  white-space: nowrap;
}

.based-excellent {
  font-size: 16px;
  letter-spacing: -0.5px;
  line-height: 19px;
}

.bottom-notification-banner {
  display: flex !important;
  margin-bottom: 72px !important;
  max-width: 630px;
  .txt-question-add {
    font-size: 19px !important;
    line-height: 23px !important;
  }
  .view-custom-btn {
    padding-left: 0px;
    color: #3f52c7;
    font-weight: bold;
    line-height: 23px;
    font-size: 19px;
    text-decoration: underline;
    text-decoration-color: #3f52c7;
  }
}

.content-referral {
  margin-top: 50px;
  white-space: nowrap;
  text-align: center;
}

.img-referral {
  width: 245px;
  height: 253px;
  margin: 50px 0px;
}

.img-referral-starbucks {
  width: 348px;
  height: 230px;
  margin: 50px 0px;
}

.is-mobile-contetn-rfr {
  display: none;
  margin-left: -15px;
}

.first-item-frr {
  margin-left: -15px;
}
// slider
.custom-link-account-group {
  background: white;
  .custom-plaid-footer {
    margin-top: 0 !important;
  }
}

.custom-modal-size {
  @media only screen and (max-width: 800px) {
    .modal-body {
      height: 610px;
    }
  }
}

.carousel-wrapper {
  .carousel-root {
    .carousel-slider {
      height: 230px;
      width: 180px !important;
      img {
        height: 100%;
      }
      .control-dots {
        bottom: -8px;
      }
    }
  }
}

.plaid-border {
  display: none;
}
.security-text-mobile {
  margin-right: -50px;
}
.security-ico-mobile {
  margin-left: -50px;
}
// slider

.ModalPlaidLinkFail {
  max-width: 592px;
  .modal-body {
    height: unset;
  }
  .ModalPlaidLinkFail-contents {
    .ModalPlaidLinkFail-title {
      color: #000000;
      font-family: Karla;
      font-size: 30px;
      font-weight: 800;
      letter-spacing: -1.8px;
      line-height: 36px;
    }
    .ModalPlaidLinkFail-subtTitle {
      margin-top: 16px;
      p {
        color: #000000;
        font-family: Karla;
        font-size: 16px;
        letter-spacing: -0.5px;
        line-height: 19px;
      }
    }
    .ModalPlaidLinkFail-btn {
      width: 100%;
      display: flex;
      margin-top: 124px;
      justify-content: center;
    }
  }
  .link-more-account-suggest {
    border-radius: 12px;
    background-color: #3f52c7;
    color: #ffffff;
    font-size: 16px;
    letter-spacing: -0.5px;
    line-height: 19px;
    text-align: center;

    position: relative;
    .txt-link-more {
      text-align: start;
      padding: 15px;
      .link-more-yes {
        margin-left: 0px !important;
      }
    }
    .btn-close-suggest {
      position: absolute;
      top: 5px;
      right: 0px;
      font-size: 14px;
      color: #ffffff;
      z-index: 1;
    }
  }
  .link-more-account-question-btn {
    margin-top: 12px;
    border: none;
  }
  &.ModalPlaidLinkFail-v2 {
    .modal-content {
      background-color: unset;
      width: 300px;
      margin-left: auto;
      margin-right: auto;
    }
    .modal-body {
      padding: 0;
    }
  }
}
.img-advice {
  width: 90px;
  height: auto;
}

.custom-card-home {
  max-height: 103px;
}

.padding-bottom-responsive {
  padding-bottom: 0 !important;
}

.custom-modal-missed-item {
  height: 550px !important;
}

@media only screen and (max-width: 768px) {
  .margin-missed-card-mobile {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .custom-details-select-small-screen {
    min-width: 340px;
  }
}
.pre-btn-paid {
  bottom: -231px !important;
  z-index: 1;
}

.next-btn-paid {
  bottom: -236px !important;
  z-index: 1;
}
