.custom-recommendation-container {
  .header-des {
    padding-left: 0px;
    padding-bottom: 16px;
  }

  .img-item-custom-recommendation {
    max-height: 55px;
    width: auto !important;
    height: auto !important;
  }
  .recommendation-custom-content-card {
    height: 121px;
    padding: 16px;
    .rw-card-grp-earned {
      margin-right: 0px;
      padding-right: 0px;
      margin-left: 0px;
      width: 100%;
    }

    .card-name {
      font-size: 16px;
      line-height: 19px;
    }
    .card-details {
      bottom: 16px;
      justify-content: space-between;
      width: calc(100% - 32px);
    }
    .spent-item-recommended {
      .spent-title {
        font-size: 12px;
      }
    }
    .rewards-item-recommended {
      .rewards-title {
        font-size: 12px;
      }
    }
    .next-ico-reward-grp {
      display: none;
    }
    .container-calc {
      margin-right: 0 !important;
    }
  }
  .btn-link-more-account {
    font-size: 16px !important;
    line-height: 19px;
    width: 223px !important;
  }
}
.modal-offer {
  width: calc(100vw - 50px);
  padding: 0px !important;
  margin: auto;
  .title-get-earning {
    font-size: 16px;
    line-height: 19px;
    display: flex;
  }
  .modal-content {
    min-height: unset !important;
    // height: 90vh !important;
  }
  .modal-body {
    min-height: unset !important;
    height: 90vh !important;
  }
  .modal-view {
    min-height: unset !important;
    // height: 90vh !important;
  }
  .label-top-categories {
    font-size: 24px;
    line-height: 28px;
    font-weight: bold;
    padding-top: 25px;
    padding-bottom: 25px;
    padding-left: 0px;
  }
}
