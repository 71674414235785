.missed-reward-wrapper {
  .missed-image-card {
    height: 40px !important;
    width: auto !important;
  }
  .spent-net-container {
    margin-right: 25px !important;
    .spent-item,
    .rewards-item {
      width: fit-content;
      min-width: auto;
    }
    .spent-title,
    .spent-price {
      width: fit-content;
      margin-right: auto;
      margin-left: 0;
    }
    .rewards-title,
    .rewards-price {
      width: fit-content;
    }
    .border-right {
      margin-left: 12px !important;
      margin-right: 12px !important;
    }
  }
  .reward-container {
    .spent-title,
    .rewards-title {
      font-size: 12px;
    }
  }

  .reward-details-content-summary {
    height: 121px !important;
    padding: 16px !important;
  }
}
