@import './modal.scss';
.logo-group {
  font-size: 1rem !important;
  cursor: pointer;
}
.sub-login-title {
  padding: 10px 0px 5px 0px;
  font-size: 20px !important;
}

.exist-account {
  padding: 5px 0px;
  font-size: 16px !important;
}

.btn-accept {
  height: 50px !important;
  width: 200px !important;
  // font-size: 20px;
  // letter-spacing: -1px;
  // line-height: 23px;
  // text-align: center;
}

.btn-signup {
  width: 200px !important;
  font-size: 20px;
  height: 50px !important;
}

.btn-signup:focus {
  width: 200px !important;
  font-size: 20px;
  height: 50px !important;
}
.btn-signup:hover {
  width: 200px !important;
  font-size: 20px;
  height: 50px !important;
}
.earned-reward-item-custom {
  .card-details {
    position: relative !important;
    bottom: 0px !important;
    padding: 0 !important;

    .img-earned-reward-item {
      position: relative;
      bottom: 0;
    }
  }
  .reward-details-content-summary {
    height: fit-content !important;
  }
}
