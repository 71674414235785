.EnableExtension-wrapper {
  padding: 24px;
  .EnableExtension-body {
    h1 {
      font-size: 24px;
      font-weight: bold;
      letter-spacing: -1px;
      line-height: 28px;

      margin-top: 40px;
    }
    .EnableExtension-slider-wrapper {
      margin-top: 60px;
      .carousel-root {
        .carousel-slider {
          width: 100% !important;
          .control-dots {
            left: 0 !important;
            .dot {
              background: rgba(0, 0, 0, 0.3);
            }
            .dot.selected {
              background: #000000;
            }
          }
        }
      }
    }
  }
}

.EnableExtensionCarouselItem {
  .EnableExtensionCarouselItem-img-wrapper {
    img {
      width: 50px;
      height: 257px;
      width: 257px;
      border-radius: 50%;

      margin: 0 auto;
    }
  }
  .item-4 {
    img {
      width: 100%;
      height: auto;

      border-radius: 0;
    }
  }
  .EnableExtensionCarouselItem-steps {
    height: 200px;
    padding-top: 70px;
    ol {
      li {
        font-size: 16px;
        letter-spacing: -0.63px;
        line-height: 19px;
        text-align: left;
      }
      .item-selected {
        font-weight: 600;
      }
    }
  }
}
