.input-custom-wrapper {
  margin-bottom: 33px;
  .input-label {
    margin-bottom: 4px;
    color: #666666;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: -0.5px;
    display: block;
  }
  .input-content {
    margin-bottom: 0px;
    border: none;
    border-bottom: 1px solid #999999;
    width: 100%;
    &:focus-visible {
      outline: none;
    }
  }
}
