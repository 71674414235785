.referral-wrapper {
  .box-img-referral {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    width: fit-content;
    margin-top: 50px;
    margin-bottom: 50px;
    img.img-referral-starbucks {
      margin: 0px;
    }
    .reward-value-card {
      font-size: 40px;
      line-height: 47px;
      font-weight: 800;
      color: white;
      position: absolute;
      bottom: 8px;
      right: 16px;
    }
  }
  .content-referral,
  .starbuck-referral {
    text-align: left;
    max-width: 512px;
    padding-left: 0;
    padding-right: 0;
    white-space: unset;
    letter-spacing: -0.67px;
  }
  .first-item-frr {
    margin-left: 0px;
  }
}
