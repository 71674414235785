.right {
  margin: auto 0px auto auto;
}

.container-page {
  overflow: auto !important;
}

.no-border {
  border: none;
}

.no-bg {
  background: none !important;
}

.btn-text-no-color {
  color: #000000;
  background: transparent;
  font-size: 16px;
  letter-spacing: -0.5px;
  line-height: 19px;
  font-weight: normal;
}
.btn-text-no-color:hover {
  color: #000000;
}
.btn-text-no-color:focus {
  color: #000000;
}

.btn-blue-text {
  background: none;
  text-decoration: underline;
  color: rgb(75, 75, 212) !important;
}
.btn-blue-text:focus {
  background: none;
  text-decoration: underline;
  color: rgb(75, 75, 212) !important;
}
.btn-blue-text:hover {
  background: none;
  text-decoration: underline;
  color: rgb(75, 75, 212) !important;
}

.footer {
  background: transparent;
  border-top: 1px solid #cccccc !important;
}

.copy-right-text {
  font-size: 12px;
  letter-spacing: -0.38px;
  line-height: 14px;
}

.select-group {
  .btn-select {
    height: 25px;
    width: 68px;
    border-radius: 1px solid #d5d5d5;
    border: 1px solid #d5d5d5;
    background-color: #ffffff;
    font-size: 16px;
    letter-spacing: -0.5px;
    line-height: 19px;
    padding: 0px;
    margin: 0px;
  }
  .btn-select:hover {
    cursor: pointer;
  }

  .btn-select:focus {
    outline: 0;
  }

  .acitve {
    background-color: #080808 !important;
    color: #ffffff;
  }
}

.no-btn {
  padding: 0px;
  border: none;
  background: none;
  margin: 0px;
  cursor: pointer;
}
.no-btn:focus {
  outline: 0;
}

.border-radius-left {
  border-radius: 4px 0 0 4px;
}

.border-radius-right {
  border-radius: 0px 4px 4px 0px;
}

.btn-month {
  margin-left: -2px !important;
}
.info-img {
  height: 14px !important;
  margin-left: 4px;
}
.arrow-up {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid #3c4db6;
  margin-left: -5px;
}

.RSPBprogressBar .RSPBprogression {
  border-radius: 0px;
}

.bottom-footer {
  position: absolute;
  bottom: 10px;
}

.footer-logo {
  .img-logo {
    height: 20px;
    width: 80px;
  }
}

.btn-info-footer {
  padding-top: 20px;
}

.nav-btn {
  border: 1px solid #cccccc;
  border-radius: 4px;
  text-align: left !important;
  padding: 5px;
}

.no-border:focus {
  outline: 0;
}

.cursor {
  cursor: pointer;
}

.right-label {
  position: absolute;
  top: 8px;
  right: 0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.btn-submit-bonus {
  height: 56px;
  width: 252px;
  font-size: 20px;
}

.group-submit-bonus {
  position: absolute;
  justify-content: center;
  text-align: center;
  bottom: 10px;
}

.current-value-progress {
  white-space: nowrap;
  /* padding-top: 300px; */
  position: absolute;
  top: 25px;
  left: -20px;
}

.link-more-account-txt {
  color: #ffffff;
  // letter-spacing: -0.5px;
  // line-height: 19px;
  border: none;
  padding: 0px;
  text-decoration: underline;
  font-weight: normal;
}

.missed-price {
  // color: #b10404 !important;
  color: #3f52c7 !important;
}

.category-name {
  position: absolute;
  bottom: 0px;
  font-size: 12px;
  letter-spacing: -0.38px;
  line-height: 14px;
}

.btn-tab-select {
  border: none;
  background: transparent;
  font-size: 12px;
  font-weight: 800;
  letter-spacing: -0.38px;
  line-height: 14px;
  padding-left: 0px;
  color: #666666;
  opacity: 0.66;
  &.active {
    opacity: 1;
    .title-select {
      color: #000000;
    }
    .border-select {
      margin-top: 5px;
      height: 3px;
      background-color: #000000;
    }
  }
}

.btn-tab-select:focus,
.btn-question-txt:focus {
  outline: 0;
}

.btn-question-txt {
  border: none;
  background: transparent;
  font-size: 12px;
  letter-spacing: -0.38px;
  line-height: 14px;
  text-decoration: underline;
}

.like-ico {
  margin-top: -5px;
}

.title-card-miss {
  font-size: 12px;
  letter-spacing: -0.38px;
  line-height: 14px;
}

.amount-missed-tst {
  margin-left: auto !important;
  font-size: 24px;
  font-weight: 800;
  letter-spacing: -1.44px;
  line-height: 28px;
  margin-top: 12px;
}

.recommend-card {
  // height: 30px;
  padding-top: 3px;
  padding-bottom: 3px;
  width: 195px;
  border-radius: 4px 4px 0 0;
  background: linear-gradient(155.27deg, #4b64ff 0%, #3c4db6 100%);
  color: #ffffff;
  text-align: center;
}

.card-details-select {
  border: 1px solid #e8e8e8;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 10px;
  border-radius: 0 12px 12px 12px;
}

.text-bold {
  font-weight: bold;
}

.your-card {
  padding-top: 3px;
  padding-bottom: 3px;
  width: 115px;
  color: #ffffff;
  text-align: center;
  border-radius: 4px 4px 0 0;
  background: linear-gradient(155.27deg, #4b64ff 0%, #3c4db6 100%);
}

.full-width {
  width: 100%;
}

.like-tst {
  margin-top: -1px;
}

.modal-earning {
  height: 450px;
  .modal-item {
    height: auto;
  }

  .details-earning {
    font-size: 16px;
    letter-spacing: -0.5px;
    line-height: 19px;
    padding-top: 20px;
  }
}

// body.modal-open > :not(.modal) {
//     -webkit-filter: blur(10px);
//     -moz-filter: blur(10px);
//     -o-filter: blur(10px);
//     -ms-filter: blur(10px);
//     filter: blur(10px);
//     z-index: -1;
//     opacity: 0.1;
// }

body.modal-open > div.bg,
.modal-backdrop.fade.show {
  z-index: 3;
  -webkit-filter: blur(11px);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  filter: blur(0px);
  opacity: 1;
  background: rgba(0, 0, 0, 0.85);
  background-size: cover;
}

.date-time-select-reward:disabled {
  background: transparent;
  cursor: auto;
  color: #000000;
}

.arrow {
  border: solid rgb(100, 100, 100);
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
}

.right-ico {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.left-ico {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.disable-arrow {
  border: solid #a5a5a5;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
}

button:disabled {
  cursor: not-allowed;
}

.title-info-modal {
  font-size: 24px;
  font-weight: 800;
  letter-spacing: -1.44px;
  line-height: 28px;
}

.content-info-modal {
  padding-top: 20px;
  font-size: 16px;
  letter-spacing: -0.5px;
  line-height: 19px;
}

.modal-info {
  top: 30%;

  .modal-content {
    height: auto !important;
    border-radius: 12px !important;
  }
}

.modal-earning-mb {
  .modal-content {
    height: 450px;
    border-radius: 12px;
  }
  height: 213px !important;
}

#pre-loader {
  background-color: #ffffff;
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 1;
  margin-top: 0px;
  top: 0px;
  left: 0px;
  bottom: 0px;
  overflow: hidden !important;
  right: 0px;
  z-index: 999999;

  .group-loader {
    text-align: center;
    left: 0;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    z-index: 99;
    margin: 0 auto;
    .creating-plaid {
      margin-top: 50px !important;
      font-size: 16px;
      letter-spacing: -0.5px;
      line-height: 19px;
    }
    .almost-detail {
      color: #c9c9c9;
    }
  }
}
.loader-wrapper {
  .text-header {
    text-align: center;
    padding-top: 100px;
    font-weight: 800;
    font-size: 16px;
    line-height: 19px;
  }
}
.prompt-title {
  font-size: 30px;
  font-weight: 800;
  letter-spacing: -1.8px;
  line-height: 36px;
}

.question-detail {
  font-size: 16px;
  letter-spacing: -0.5px;
  line-height: 19px;
  margin-top: 20px;
}

.question-item {
  font-size: 16px;
  font-weight: 800;
  letter-spacing: -0.5px;
  line-height: 19px;
}

.label-checkbox::before {
  margin-top: 13px;
  width: 24px !important;
  height: 24px !important;
}
.label-checkbox::after {
  margin-top: 16px;
  margin-left: 3px;
  width: 18px !important;
  height: 18px !important;
}

.label-checkbox {
  margin-left: 10px !important;
  margin-top: 2px !important;
}
.modal-margin-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.modal-skip {
  .modal-content {
    border: none;
  }

  .body-modal-skip {
    height: 570px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

.btn-continue-prompt {
  height: 56px;
  width: 223px;
  border-radius: 28px;
}

.btn-skip {
  background: transparent;
  border: none;
  margin: auto;
  margin-top: 20px;
  font-size: 16px;
  letter-spacing: -0.5px;
  line-height: 19px;
  color: #ffffff;
  opacity: 0.8;
  text-decoration: underline;
  cursor: pointer;
}

.btn-skip:focus {
  outline: 0;
}

.is-red-bottom-boder {
  border-bottom: 1px solid #e90000 !important;
}

.no-background {
  background: transparent;
}
.btn-close-modal-skip {
  font-size: 28px;
  width: 30px;
  height: 30px;
  line-height: 30px;
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
}

button {
  cursor: pointer;
}

.border-right {
  border-right: 1px #cccccc;
}

.full-height {
  height: 100%;
}

.user-profile {
  margin-top: 80px;
}

.your-rewards-summary {
  font-size: 16px;
  letter-spacing: -0.96px;
  line-height: 19px;
  font-weight: normal;
}

.number-title {
  background-color: #b10404;
  padding: 6px 8px;
  border-radius: 50%;
  margin-right: 10px;
  color: #ffffff;
  font-size: 12px;
  font-weight: 800;
  letter-spacing: -0.72px;
  line-height: 14px;
}

.info-ico-reward {
  height: 18px;
  width: 18px;
  margin-left: 10px;
  cursor: pointer;
}

.white-color {
  color: #ffffff !important;
}

input {
  padding-bottom: 5px !important;
}

.border-radius {
  border-radius: 12px;
}

.verification-title-login {
  font-size: 30px;
  font-weight: 800;
  letter-spacing: -1.25px;
  line-height: 36px;
}

.link-more-account-question-btn {
  letter-spacing: -0.6px;
  line-height: 14px;
  text-align: center;
  border-radius: 28px;
  background-color: #ffffff !important;
  // height: 32px;
  padding: 8px 20px;
}

.txt-question-add {
  font-size: 16px !important;
  letter-spacing: -0.5px !important;
  line-height: 19px !important;
}

.btn-year {
  border-radius: 0px 100px 100px 0px;
  height: 25px;
  width: 68px;
}

.btn-month {
  border-radius: 100px 0 0 100px;
  height: 25px;
  width: 68px;
}

.modal-offer {
  .modal-content {
    border-radius: 12px;
  }
  .title-get-earning {
    font-size: 16px;
    line-height: 19px;
  }
}

.is-red-color {
  color: #b10404;
}

.ico-earned-popup {
  margin-left: 5px;
  margin-bottom: 5px;
}

.title-max {
  // padding-right: 10px !important;
}

.txt-max {
  padding: 5px 10px;
  background: rgb(243, 189, 13);
  border-radius: 20px;
  font-size: 10px;
}

.date-title-earned {
  // margin-top: 8px;
}

.mono-font-size {
  font-size: 12px;
  @media screen and (max-width: 600px) {
    font-size: 9px;
  }
}

.card-category {
  font-size: 16px !important;
  letter-spacing: -0.5px;
  line-height: 19px;
}

.next-ico-tst-missed {
  margin-top: 5px;
}

.modal-content {
  height: auto !important;
  border-radius: 12px !important;
}

.title-get-earning {
  font-size: 24px;
  font-weight: 800;
  letter-spacing: -1.44px;
  line-height: 28px;
}

.modal-missed-item {
  position: relative !important;
  height: 500px;
  .modal-item {
    height: 100%;
  }

  .details-earning {
    font-size: 16px;
    letter-spacing: -0.5px;
    line-height: 19px;
    padding-top: 20px;
  }
}

.modal-earning-maximum {
  height: 190px;
  .modal-item {
    height: auto;
  }

  .details-earning {
    font-size: 16px;
    letter-spacing: -0.5px;
    line-height: 19px;
    padding-top: 20px;
  }
}

.modal-earning-mb-maximum {
  // top: 20%;
  .modal-earning-maximum {
    height: unset;
  }
}

.recommended-card-details {
  padding-right: 30px;
  padding-top: 20px;
}

.recommended-card-name {
  max-width: 190px;
  font-size: 16px;
  letter-spacing: -0.5px;
  line-height: 19px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.recommended-earn-each {
  padding-left: 30px;
  border-left: 1px solid #cccccc;
}

.recommended-title-item {
  font-size: 16px;
  font-weight: 800;
  letter-spacing: -0.5px;
  line-height: 19px;
}

.based-on-your-last {
  font-size: 12px;
  letter-spacing: -0.38px;
  line-height: 14px;
  padding: 20px 0px;
}

.recommended-money-title {
  font-size: 30px;
  font-weight: 800;
  letter-spacing: -1.8px;
  line-height: 36px;
  text-align: right;
}
.recommended-money-title-small {
  font-size: 16px;
  line-height: 19px;
}

.card-list-dt {
  font-size: 16px;
  letter-spacing: -0.5px;
  line-height: 19px;
}

.card-list-dt-scroll {
  overflow-x: auto;
  min-height: 24px;
}

.fts-card {
  border-right: 1px solid #cccccc;
  padding-right: 20px;
  margin-right: 10px;
}

.recommendation-custom-content-summary {
  height: 168px;
  border: 1px solid #e8e8e8;
  cursor: pointer;
  border-radius: 0px 12px 12px 12px;
  background-color: #ffffff;
  padding: 20px;
  // box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.1);
}

.top-notification-banner {
  height: 48px;
  border-radius: 12px;
  background-color: #3f52c7;
  color: #ffffff;
  font-size: 16px;
  letter-spacing: -0.5px;
  line-height: 19px;
  text-align: center;
  // margin-top: 30px;
  margin-right: 20px;
  margin-bottom: 30px;
  position: relative;
  .txt-link-more {
    padding-top: 10px !important;
  }
}

.view-custom-btn {
  letter-spacing: -0.6px;
  line-height: 14px;
  text-align: center;
  border-radius: 28px;
  background-color: #ffffff !important;
  // height: 32px;
  padding: 8px 20px;
  border: none;
}

.personalized-more {
  font-size: 30px;
  font-weight: 800;
  letter-spacing: -0.75px;
  line-height: 35px;
  margin-bottom: 30px;
}

.border-center-item {
  background-color: #dddddd !important;
  width: 150vw;
  height: 4px !important;
  margin-left: -200px;
  position: relative;
}

.recommendation-slide-grp {
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.1);
  border-radius: 0px 12px 12px 12px;
}

.your-best-card {
  background-color: #3f52c7;
  padding: 10px;
  width: 235px;
  color: #ffffff;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: -0.5px;
  line-height: 19px;
  text-align: center;
  border-radius: 4px 4px 0 0;
  margin-left: 1px;
}

.card-name-recommanded {
  height: 30px !important;
  text-align: left;
}

.you-could-earn {
  font-size: 16px;
  letter-spacing: -0.38px;
  line-height: 19px;
  margin-right: 20px;
  text-align: right;
}

.arrow-next-slide {
  border: solid rgb(100, 100, 100);
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 5px;
}

.right-ico {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.left-ico {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.disable-arrow-next-slide {
  border: solid #a5a5a5;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 5px;
}

.img-dot {
  margin-top: -5px;
}
.slider-recommanded-grp {
  width: 95vw;
  margin-left: -7.5vw !important;
}

.control-group-slider {
  height: 150px;
  margin-top: -120px;
  z-index: 2;
  background: white;
  padding-top: 20px;
  @media only screen and (max-width: 1023px) and (min-width: 601px) {
    margin-top: -70px;
  }
}

.best-card {
  width: 92px;
  border-radius: 9px;
  background-color: #ffd551;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: -0.38px;
  line-height: 14px;
  text-align: center;
  padding: 3px;
}

.additional-benefits {
  width: 131px;
  border-radius: 9px;
  background-color: #3f52c7;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: -0.38px;
  line-height: 14px;
  text-align: center;
  color: #ffffff;
  padding: 3px;
  margin-top: 34px;
}

.card-category-recommended-details {
  // height: 123px;
  border: 1px solid #e8e8e8;
  border-radius: 12px;
  background-color: #ffffff;
}

.card-category-recommended {
  height: 62px;
  border: 1px solid #e8e8e8;
  border-radius: 12px;
  background-color: #ffffff;
}

.border-bottom-category-details {
  width: 100%;
  border-bottom: 1px solid #cccccc !important;
}

.additional-rewards {
  margin-top: 34px;
  height: 18px;
  width: 124px;
  border-radius: 9px;
  background-color: #3f52c7;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: -0.38px;
  line-height: 14px;
  text-align: center;
  color: #ffffff;
  padding: 3px;
}

.additional-benefits-details {
  font-size: 16px;
  letter-spacing: -0.5px;
  line-height: 19px;
}

.content-thanks-page {
  margin-top: 62px;

  .icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    background: linear-gradient(155.27deg, #4b64ff 0%, #3c4db6 100%);
    width: 53px;
    min-width: 53px;
    height: 60px;
    margin-right: 13px;
  }
}

.skip-thanks-page {
  display: flex;
  justify-content: center;
  color: #3f52c7;
  font-size: 16px;
  letter-spacing: -0.67px;
  line-height: 19px;
  text-decoration: underline;
  margin-top: 28px;
}

.additional-rewards {
  width: 131px;
  border-radius: 9px;
  background-color: #3f52c7;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: -0.38px;
  line-height: 14px;
  text-align: center;
  color: #ffffff;
  padding: 3px;
}

.recommanded-money {
  font-size: 16px;
  font-weight: 800;
  letter-spacing: -0.75px;
  line-height: 19px;
  color: #000000;
}

.recommanded-point {
  font-size: 12px;
  letter-spacing: -0.38px;
  line-height: 14px;
  text-align: right;
}

.total-cost-recommanded {
  font-size: 30px;
  font-weight: 800;
  letter-spacing: -0.94px;
  line-height: 30px;
  color: #3f52c7 !important;
}

.d-none {
  display: none !important;
}

.border-bottom-modal {
  // width: 100%;
  height: 20px;
  width: calc(100% + 40px + 2rem) !important;
  border-bottom: 1px solid #dddddd;
  margin-left: calc(-20px - 1rem);
}

.modal-card-details {
  border: 1px solid #e8e8e8;
  border-radius: 12px;
  background-color: #ffffff;
  padding-top: 20px !important;
  // padding-bottom: 20px !important;
}

.modal-cash {
  margin-bottom: 10px;
  font-size: 24px;
  letter-spacing: -0.75px;
  line-height: 14px;
  font-weight: 800;
  text-align: right;
}

.modal-cash-title {
  margin-bottom: 10px;
  font-size: 12px;
  letter-spacing: -0.75px;
  line-height: 14px;
  text-align: right;
}

.title-card-modal {
  font-size: 12px;
  letter-spacing: -0.38px;
  line-height: 14px;
}

.btn-modal-bottom {
  text-align: center;
}

.btn-continue-mxm {
  height: 56px;
  width: 223px;
}

.based-excellent {
  font-size: 12px;
  letter-spacing: -0.38px;
  line-height: 14px;
}

.btn-excellent {
  border: none;
  padding: 0px;
  background: transparent;
  font-weight: 800;
  text-decoration: underline;
}

.btn-excellent:focus {
  outline: 0;
}

.modal-recommended-maximum {
  height: 380px;

  .modal-item {
    height: auto;
  }

  .details-earning {
    font-size: 16px;
    letter-spacing: -0.5px;
    line-height: 19px;
    padding-top: 20px;
  }
}
.modal-recommended-mb-maximum {
}

.title-chances {
  font-size: 30px;
  font-weight: 800;
  letter-spacing: -1.8px;
  line-height: 36px;
  margin-bottom: 20px;
}

.modal-recommended-learn-more {
  min-height: 350px;
  .modal-item {
    height: auto;
  }

  .details-earning {
    font-size: 16px;
    letter-spacing: -0.5px;
    line-height: 19px;
    padding-top: 20px;
  }
}

.btn-modal-bottom-learn-more {
  margin-top: 20px;
  text-align: center;
}

.custom-body {
  height: fit-content;
}

.recommendation-custom-content-card {
  height: 168px;
  border: 1px solid #e8e8e8;
  border-radius: 12px;
  background-color: #ffffff;
  padding: 20px;
  // box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.1);
}

.spent-item-recommended {
  font-size: 16px;
  letter-spacing: -0.38px;
  line-height: 19px;
  margin-top: 16px;
  min-width: 90px;
  padding-right: 10px !important;
  border-right: 1px solid #cccccc;
}

.rewards-item-recommended {
  font-size: 16px;
  // font-weight: 800;
  min-width: 90px;
  letter-spacing: -0.38px;
  line-height: 19px;
  margin-top: 16px;
  padding-left: 10px !important;
}

.google-captcha-container {
  display: flex;
  justify-content: center;
}

.card-recommed-categrogy {
  white-space: nowrap;
  img {
    margin-top: -10px;
    margin-right: 2px;
  }
}

.modal-about-reward {
  height: 250px;
}

.title-referral {
  // margin-top: 50px;
  font-size: 30px;
  font-weight: 800;
  letter-spacing: -1.25px;
  line-height: 36px;
  text-align: center;
}

.react-share__ShareButton:focus {
  outline: 0;
}

.social-share-group {
  // justify-content: center;
  height: 56px;
  width: 173px;
  border: 1px solid #3f52c7;
  border-radius: 28px;
  margin: auto;
  img {
    margin-top: 18px;
    height: 20px;
    border-radius: 2px;
    margin-left: 20px;
  }
  div {
    padding-top: 18px;
    margin-left: 35px;
    font-size: 16px;
    letter-spacing: -0.8px;
    line-height: 19px;
    text-align: center;
    color: #3f52c7;
    // color: #ffffff;
  }
}

.reward-custom-d-flex {
  height: calc(342px + 20px + 0.5rem + 20px);
  @media (max-width: 767px) {
    height: unset;
  }
}

@media (min-height: 1024px) and (min-width: 750px) {
  .reward-custom-d-flex {
    height: 370px !important;
    // margin-top: 175px;
  }

  .spending-item {
    .next-ico-reward-grp {
      bottom: 25px !important;
    }
  }

  .missed-summary.personalized-item {
    .next-ico-reward-grp {
      bottom: 24px !important;
    }
  }

  .earned-summary,
  .missed-summary-rectangle {
    @media (max-width: 840px) {
      min-width: unset !important;
    }
    min-width: 200px;
  }
  @media (min-height: 1100px) {
    .personalized-content-grp {
      margin: unset !important;
      // margin-top: 180px !important;
      margin-top: auto;
      margin-bottom: auto;
    }
  }
  .personalized-content {
    .spending-item {
      padding-bottom: 16px !important;
      padding-right: 0px !important;
      padding-left: 0px !important;
    }
    .missed-summary {
      padding-right: 0px !important;
      padding-left: 0px !important;
    }
    .reward-content-summary {
      max-width: 90% !important;
      flex: unset !important;
    }
  }
}

.social-group {
  padding-top: 50px;
  padding-bottom: 50px;
}

.social-group-list-btn {
  margin-left: 0%;
  text-align: center !important;
}

.custom-item-body-referral {
  min-height: calc(100vh - 279px);
}
.bnt-img {
  background: unset !important;
  border: none !important;
  &:active {
    box-shadow: none !important;
    background: unset;
    background-color: unset !important;
    border: none !important;
  }
  &:active {
    box-shadow: none !important;
    background: unset;
    background-color: unset !important;
    border: none !important;
  }
}

.title-invite {
  font-size: 16px;
  font-weight: 800;
  letter-spacing: -1px;
  line-height: 19px;
  text-align: center;
}
.text-decoration-none {
  text-decoration: none !important;
}
.mt-120 {
  margin-top: 120px !important;
}
.text-bold {
  font-weight: bold;
}
.bnt-normal-size {
  font-size: 16px !important;
  line-height: 19px !important;
}
.font-size-sm {
  font-size: 12px;
  line-height: 14px;
}
.card-not-current {
  margin-top: 70px;
}
.color-not-change {
  &:hover {
    color: currentColor !important;
  }
}
.based-spent-recommanded {
  font-size: 13px;
}

.search-input-custom {
  display: flex;
  align-items: center;
  padding-bottom: 12px;
  border-bottom: 1px solid #999999;

  input {
    flex-grow: 1;
    margin: 0px 10px;
    border: none;
    &:focus {
      outline: none;
    }
  }

  .close-search {
    cursor: pointer;
  }
}

.search-card-container {
  padding: 27px;

  .search-card-item {
    color: #000000;
    font-family: Karla;
    font-size: 16px;
    letter-spacing: -0.5px;
    line-height: 19px;
    margin-bottom: 27px;
    cursor: pointer;
  }
}

.dot-fee {
  background: rgb(51, 51, 51);
  height: 6px;
  width: 6px;
  margin-top: 10px;
  margin-right: 10px;
  border-radius: 50%;
}

.month-home-custom {
  .month-select {
    display: none !important;
  }
}
.footer-wrapper {
  height: fit-content !important;
  min-height: unset !important;
  max-height: unset !important;
  padding-left: 4vw !important;
  padding-right: 4vw !important;
  padding-top: 3.3vmax !important;
  padding-bottom: 3.3vmax;
  .logo-container {
    height: fit-content;
    margin-bottom: 24px;
  }
  .footer-logo {
    height: fit-content;
  }
  .title-section {
    font-size: 12px;
    line-height: 14px;
    letter-spacing: -0.37px;
    color: white;
    font-weight: 800;
    margin-bottom: 8px;
  }
  .sub-title-section {
    font-size: 12px;
    line-height: 14px;
    letter-spacing: -0.37px;
    color: white;
    margin-bottom: 12px;
    &:last-child {
      margin-bottom: 0px;
    }
  }
  .backgroundLanding--store {
    margin-bottom: 8px;
    width: 120px;
    height: auto;
    display: block;
    &:last-child {
      margin-bottom: 0px;
    }
  }
  .social-grp {
    margin-top: 22px;

    @media (max-width: 480px) {
      width: 70%;
    }
    @media (max-width: 400px) {
      width: 90%;
    }
  }
  .social-item-box {
    display: inline-block;
    margin-right: 20px;
    &:last-child {
      margin-right: 0px;
      img {
        width: 24px;
      }
    }

    @media (max-width: 480px) {
      &:nth-child(4) {
        img {
          margin-top: 15px;
        }
      }
      &:last-child {
        img {
          margin-top: 15px;
        }
      }
    }
    .social-icon {
      height: 16px;
      width: 16px;
    }
  }
  .mono-font-size {
    font-size: 9px;
    line-height: 10px;
  }
  .des-section {
    margin-top: 42px;
  }
  .copy-right-section {
    font-size: 12px;
    line-height: 14px;
    color: white;
    margin-top: 40px;
  }
  .box-section {
    @media screen and (max-width: 600px) {
      padding-left: 0;
      margin-top: 40px;
      &:first-child {
        margin-top: 0px;
      }
    }
    @media screen and (max-width: 767px) {
      padding-left: 0;
      margin-top: 40px;
      &:first-child {
        margin-top: 0px;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .footer-wrapper {
    .backgroundLanding {
      .section-landing-1 {
        margin-top: 24px;
        margin-bottom: 130px;
        margin-left: 0;
        margin-right: 0;
        &--contents {
          width: fit-content;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          padding-left: 0;
        }
        &--col {
          display: flex;
          justify-content: center;
        }
        &--store-contents-desktop {
          display: none;
        }
        &--store-contents-mobile {
          margin: 0 auto;
          margin-top: 36px;
        }
        p {
          margin-bottom: 40px;
        }
        &--col-phone {
          display: flex;
          justify-content: center;
        }
      }
      .section-landing-2 {
        &--col {
          display: flex;
          justify-content: center;
          padding-right: 0;
        }
        &--content-desktop {
          display: none;
        }
        &--content-mobile {
          display: block;
          h1 {
            width: 300px;
          }
        }
        p {
          margin-bottom: 40px;
          width: 212px;
        }
      }
      h1 {
        height: 80px;
        width: 330px;
        color: #000000;
        font-family: 'Sharp Grotesk Medium 25';
        font-size: 36px;
        letter-spacing: -0.6px;
        line-height: 40px;
        text-align: center;
        margin: 0 auto;
        margin-bottom: 16px;
      }
      p {
        height: 46px;
        width: 193px;
        color: #000000;
        font-family: Karla;
        font-size: 20px;
        letter-spacing: -0.63px;
        line-height: 23px;
        text-align: center;
        margin: 0 auto;
        margin-bottom: 10px;
      }

      &--phone {
        height: 398px;
        width: 183px;
      }
      &--store {
        height: 31px;
        width: 93px;
        &:first-child {
          margin-right: 11px;
        }
      }
    }
  }
}
.normal-font-weight {
  font-weight: normal;
}
.starbuck-referral {
  text-align: center;
}
.rw-card-grp-earned-spent-reward {
  margin-bottom: 0 !important;
}
// .reward-container {
//   padding-top: 90px !important ;
// }

.carousel-wrapper {
  .carousel-root {
    .carousel-slider {
      margin: 0 auto;

      .control-dots {
        .dot {
          background: rgba(0, 0, 0, 0.3);
        }
        .dot.selected {
          background: #000000;
        }
      }
    }
  }
}
.Plaid-title {
  width: 425px;
  color: #000000;
  font-family: Karla;
  font-size: 24px;
  font-weight: 800;
  letter-spacing: -1px;
  line-height: 28px;
  margin: 0 auto;
  padding-bottom: 20px;
  border-bottom: 1px solid #ccc;
  @media (max-width: 425px) {
    width: 100%;
  }
}
.Plaid-subtitle {
  color: #999999;
  font-family: Karla;
  font-size: 16px;
  letter-spacing: -0.83px;
  line-height: 23px;
  text-align: center;
  margin-top: 20px;
}

.mt-20 {
  margin-top: 20px !important;
}

.bm-menu-wrap {
  background: white;
  width: 100% !important;
  margin-top: 21px;
}

#react-burger-menu-btn {
  display: none;
}

.bm-overlay {
  background: transparent !important;
}

.header-back-grp-menu {
  padding-top: 50px;
  padding-bottom: 100px;
}

.btn-back-menu-group {
  background: transparent;
  .btn-back-menu {
    height: 30px;
  }
}

.btn-back-menu-group:focus {
  outline: 0;
  background: transparent;
}

.btn-back-menu-group:hover {
  background: transparent;
  color: inherit;
}

.menu-gpr {
  display: flex !important;
}

.menu-item {
  padding-bottom: 25px;
  padding-top: 25px;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: -0.67px;
  line-height: 19px;
  cursor: pointer;
}

.sign-out {
  color: #3f52c7;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: -0.67px;
  line-height: 19px;
}

.next-ico-menu-item {
  position: absolute;
  right: 10px;
  top: 15px;
}

.mobile-border-bottom {
  border-bottom: 1px solid #cccccc !important;
}

.hamburger-react {
  z-index: 10000;
}

@media only screen and (max-width: 320px) {
  .reward-item {
    .card-details {
      .custom-divider-2 {
        margin-right: 10px !important;
        margin-left: 10px !important;
        padding-right: 0;
        padding-left: 0;
      }
    }
  }
}

.custom-btn-link-account {
  margin-top: 10px !important;
}

.hamburger-react {
  div {
    &:nth-child(2) {
      top: 21px !important;
    }
    &:last-child {
      top: 29px !important;
    }
  }
}

.custom-hamburger {
  .hamburger-react {
    div {
      &:last-child {
        top: 33px !important;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .list-desktop {
    display: none !important;
  }
}

@media only screen and (min-width: 768px) {
  .list-mobile {
    display: none !important;
  }
}

.center-menu-title {
  margin-left: -92.2px;
}

.col-left {
  flex: 0 0 calc(50% - 15px) !important;
  max-width: calc(50% - 15px) !important;
}

.col-right {
  margin-left: 15px !important;
}

.padding-top-0 {
  padding-top: 0 !important;
}

.margin-bottom-5 {
  margin-bottom: 5px !important;
}

.min-width-100 {
  min-width: 100px;
}

.reset-position {
  position: unset !important;
}

// .mobile-last-title-login {
//   font-size: 1rem !important;
//   font-weight: 400 !important;
//   text-align: center !important;
// }

.spacing-missed-item-fix {
  margin-top: 10px;
  line-height: 19px !important;
}

.update-point {
  margin-top: 10px;
}

.best-card-flex {
  display: flex;
  flex-direction: row;
  .img-flex {
    flex: 0 0 32px;
  }
  .category-flex {
    margin: auto;
    margin-left: 0px;
  }
}

button:focus {
  outline: none !important;
}

.phone-input-disable,
.email-input-disable {
  .input-content {
    border-bottom: 1px solid transparent !important;
    pointer-events: none;
    padding-left: 0;
  }
}

.indent {
  padding-left: 0.5rem;
  text-indent: -0.5rem;
}
.align-item-center {
  align-items: center !important;
}

.margin-top-0 {
  margin-top: 0 !important;
}

.padding-left-0-mobile {
  @media only screen and (max-width: 767px) {
    padding-left: 0 !important;
  }
}

.font-size-12 {
  font-size: 12px !important;
}

.margin-bottom-0 {
  margin-bottom: 0 !important;
}

.plus-more-blue {
  color: #3f52c7;
}

body {
  min-height: -webkit-fill-available;
}
html {
  height: -webkit-fill-available;
}

.flex-btn-paid {
  display: flex;
  flex-direction: row;
}

.pre-btn-paid {
  position: relative;
  flex: 0 0 calc((100% - 89px) / 2);
  display: flex;
  justify-content: flex-end;
}

.padding-left-right {
  flex: 0 0 82px;
}

.next-btn-paid {
  position: relative;
  display: flex;
  justify-content: flex-start;
}
#scrollbar-custom-card-list::-webkit-scrollbar {
  height: 5px;
  background-color: #f5f5f5;
}
#scrollbar-custom-card-list::-webkit-scrollbar-thumb {
  background-color: #d5d5d5;
}

.justify-flex-center {
  justify-content: center !important;
}

@media screen and (max-width: 767px) {
  _::-webkit-full-page-media,
  _:future,
  :root .safari_only {
    padding-bottom: 115px !important; //resize
  }
}

.border-none {
  border: none;
}
.modal-content {
  .custom-modal-items {
    padding-left: 0 !important;
    padding-right: 0 !important;
    .modal-item {
      height: auto;

      .modal-estimate-content-custom {
        @media only screen and (max-width: 767px) and (min-width: 321px) {
          padding-top: 20px !important;
        }
      }

      .top-card-modal-wrapper {
        .your-top-card-custom {
          height: 18px;
          width: fit-content;
          padding-left: 14px;
          padding-right: 14px;

          display: flex;
          align-items: center;
          justify-content: center;

          margin-bottom: 14px;
          background-color: #ffd551;
          border-radius: 9px;
        }
        .card-detail-container-custom {
          padding-left: 14px;
        }
        .btn-next-modal {
          margin-top: 100px;
          margin-bottom: 30px;

          text-align: center;

          @media only screen and (max-width: 767px) and (min-width: 321px) {
            margin-top: 200px;
          }
        }
        .custom-card-detail-header {
          display: flex;
          align-items: center;
          margin-bottom: 24px;
          .name-card {
            margin-left: 16px;
          }
        }
        .custom-info-card {
          .bold {
            font-weight: bold;
          }
          .underline {
            text-decoration: underline;
          }
        }
      }
    }
  }
}

.icon-marker {
  width: 41px;
  height: 46px;
}

.best-card-for-content {
  padding: 2px 12px;
  border-radius: 9px;
  width: fit-content;
  background-color: #ffd551;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: -0.38px;
  line-height: 14px;

  margin-bottom: 8px;
}

.modal-view-all-benefit {
  .custom-title-get-earning {
    .card-detail-wrapper-custom {
      display: flex;
      align-items: center;
    }
    @media only screen and (max-width: 767px) {
      display: flex;
      flex-direction: column;
    }
  }
}
